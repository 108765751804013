# 我的第一胎，出生只花了 30 分鐘

_March 8, 2021 by [Doris](/)_

<figure align="center">
<img alt="西班牙戶口名簿" src="https://d30ay7ug033loj.cloudfront.net/images/my-first-baby.jpeg" width="100%"/>
</figure>

在最後一次產檢，也就是 39 週的時候，醫生說因為胎頭還不夠下沉，判斷寶寶應該還沒這麼快出來，當天是 2020 年 12 月 18 日星期五的早診。醫生說他週日、週一會休假遠行，所以趕得上接生。叫我不用擔心。

<div align="center">
<figure style="width:40%;">
<img alt="ultrasound 3d" src="https://d30ay7ug033loj.cloudfront.net/images/image-1-768x472.jpeg" width="100%"/>
<figcaption>這天產檢Edu寶寶比了OK</figcaption>
</figure>
</div>
<br>

週五產檢後，醫生提醒我最後幾天要多動，幫助胎頭下沉，所以山地逼迫我從長安東路散步到忠孝復興站，吃飽後又繼續散步回家，手錶紀錄當天走了近 11,000 步。 ( ಠ \_\_ ಠ ; ) 他是不是忘記我 39 週孕婦？

週六，我們還很活躍地去逛了 IKEA，邊討論如何增加寶寶的收納空間。當天走了 7,500 步。神采奕奕，除了上網申請油飯試吃外，也把待產行李箱都準備好了。Ready Ready

週日睡飽睡滿（現在的我才知道這是最後一次睡飽），起床後發現落紅了，急呼山地見證這一切。後來開始出現輕微的陣痛，類似月經的悶痛感。躺在沙發上大口調整呼吸，『原來這就是陣痛啊，好像還好～』**對不起！我真的太無知了**。

<div align="center">
<figure style="width:40%;">
<img alt="pregnant woman" src="https://d30ay7ug033loj.cloudfront.net/images/image-768x658.jpeg" width="100%"/>
<figcaption>週日早上還可以笑嘻嘻坐瑜珈球</figcaption>
</figure>
</div>
<br>

山地下載了[宮縮計時器 APP](https://play.google.com/store/apps/details?id=com.neiman.contractions)，只要開始痛我就喊他；如果到了可以送醫院的陣痛頻率，APP 也會通知，對待產父母蠻好用的，推薦大家下載。

二姊趕來關心我的待產進度，「第一胎沒那麼快啦，醫生也說還沒，你不要擔心」，轉述完醫生的話就把姊姊趕走。<span className="purple-color">此時大約一小時有 3 次宮縮。</span>我漸漸可以掌控陣痛來的感覺，於是趁不怎痛的時候，跟山地從 11 樓走到 2 樓，來回二趟，挺著肚子其實蠻喘。

半夜三點，陣痛得睡不著，多希望 APP 通知『可以準備去醫院了』，等到五點受不了去醫院。<span className="purple-color">這時大約一小時有 5 次宮縮。</span>量了胎心音也內診，被護士退貨。「只有開一指，你要回家或是在這裡等都可以。不過你第一胎，沒這麼快。」橫豎都要等，不如回家睡覺。於是我們拎著待產行李箱回家了。

因為半夜沒睡好，一回家後又睡著。還記得邊睡肚子邊痛，可能真的累了，沒留意到陣痛的強度和頻率，終於到了這一刻。早上八點多，我在床上痛到大哭，跟山地說我不行了。根據宮縮 APP 紀錄，<span className="purple-color">從八點到九點之間有 8 次宮縮</span>。還記得從床上坐起、穿上衣服、走出門、搭上計程車，都是非常痛苦的過程。那個疼痛的感覺就像是有人徒手扒開骨盆腔，痠痛撕裂。

非常感謝當時的計程車司機，因為我在車上的宮縮已經進展到<span className="purple-color">每三分鐘一次</span>，不斷趕路的司機一遇上紅燈，可能比我還著急，我連痛得要命都還聽到他的「嘖嘖」與催油門聲。

<div align="center" >
<figure style="width:80%;max-width:320px;">
<img alt="contractions" src="https://d30ay7ug033loj.cloudfront.net/images/image.jpeg" width="100%"/>
<figcaption>這是最後一小時的宮縮紀錄，左邊是宮縮開始的時間，右邊是結束的時間</figcaption>
</figure>
</div>
<br>

到了診所，警衛見到表情猙獰的我問「今天有掛號嗎？」「我快要生了」「那你還能走嗎？」「不行！」輪椅馬上推來讓我坐上。我還記得一樓掛號區附近滿是人，二樓看診區也是。到了看診區的護理站，正準備胎心音儀器的護士問「破水了嗎？」「沒有」「現在多久宮縮一次？」「3分鐘」「那我們快給醫生看診。」

轉台到門診內，「把內褲脫下，躺在那裡」護士熟練地說。「我正在陣痛」，「那等你結束再說」。好不容易捱過一次陣痛的我躺下，醫生看了說「破水了也全開了，通知產房」。我想起產前的衛教課說，「我們來這裡就是要**優雅的生產**，不用去挑戰人類忍痛極限」，「我要打無痛」我用盡最後力氣喊著，「已經全開了，你來不及打了」，被醫生拒絕的我當下晴天霹靂。

再度轉台到產房。那裡有兩張產台，中間用布簾隔開。另一床是剖腹產，醫生和產婦談笑風生。每3分鐘陣痛一次的我，失去打無痛門票的我，在床上慘叫的我，聽到隔壁床在竊笑。『如果能選擇，我還會落得這副德性嗎我』，在產台上的回憶不太好，我記得我用力錯誤、護士雙拳壓肚子、醫生在前面鼓舞著、山地進來扶著我肩膀、我尖叫不斷重複地說我不行了，我好害怕我生不出來。接著就聽到醫生的恭喜和孩子的哭聲。

<div align="center" >
<figure style="width:40%;">
<img alt="new born baby" src="https://d30ay7ug033loj.cloudfront.net/images/image-2.jpeg" width="100%"/>
<figcaption>剛出生的嬰兒真的很難好看</figcaption>
</figure>
</div>
<br>

電影都這麼演，聽到孩子的第一個哭聲，媽媽就會破涕為笑。但生完的那刻我是一片空白的，我傻了，愣住了，只知道肚子不再疼，眼角還掛著淚。剛剛的恐懼、那場混亂，正式宣告我成為了一位母親。母親是什麼？誰來教我該怎麼當一位母親？

看著計程車搭乘紀錄，10點抵達，30分鐘內孩子就生出來了。做完清潔和縫合，11點多被推出產房，看到二姊和先生，我強忍著害怕，掛上招牌笑容，接受家人們的恭喜。

<div align="center" >
<figure style="width:40%;">
<img alt="father with new born baby" src="https://d30ay7ug033loj.cloudfront.net/images/image-3.jpeg" width="100%"/>
<figcaption>別以為這張父慈子孝，其實嬰兒應該看不到眼前人</figcaption>
</figure>
</div>
<br>

必須老實說，有了小孩是件很幸福的事，但不會因此就忘了自己在生產過程的感受。那麼我還願意生第二胎嗎？看在小孩很可愛又很療癒的份上，我願意。

<div align="center" >
<figure style="width:30%;">
<img alt="father with new born baby" src="https://d30ay7ug033loj.cloudfront.net/images/image-4-768x870.jpeg" width="100%"/>
<figcaption>一個月大的Edu</figcaption>
</figure>
</div>