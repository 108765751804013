# 懷孕後，每天都在重新認識自己身體

_June 20, 2020 by [Doris](/)_

<figure align="center">
<img alt="ultrasound pic" src="https://d30ay7ug033loj.cloudfront.net/images/baby.jpeg" width="100%"/>
</figure>

此刻的我大概懷孕 13 週。

這話說得非常有道理（誇自己），因為身體確實每天都在改變。昨天吃的食物很美味，可能今天就讓你反胃。上一秒還可以跟同事正常對話，下一秒就一陣胃痛或孕吐。非常難以捉摸，有時候我都為山地覺得心疼，希望他不要覺得我一直在找碴。

以下分享一些自己的懷孕初期症狀。

##### 胸部腫脹

就像是生理期來之前一樣，整個胸部會有些腫脹敏感，但懷孕後的腫脹是不會消退的啊！而且乳頭非常非常敏感，例如換衣服碰到、睡覺壓到，就是超級痛啊 ಠ_ಠ 真的不是普通痛。如果它一直長一直漲，哪天變成凶器我也不意外。

##### 骨盆痠軟

這也跟生理痛很像，但少了生理痛的抽筋感，多的是骨盆腔上的痠軟。超痠超痠痠到站不直，山地幫我敲敲打打推拿也無用，我只能對正在幫我按摩的他說「我…恨….你…..」，反正他也聽不懂。這症狀比較像是前幾週會發生的事情。此刻的我好像脫離了下腹痠痛（但也不好說）。

##### 胃口改變

我變得討厭堅果。原本可以狂嗑 costco 無鹽堅果的，懷孕之後堅果的油脂味讓我不太舒服，導致連花生醬、芝麻醬、綜合堅果醬這種我都不太行。不是那種完全吃不下的那種，只是吃它失去了愉悅感。

某天吃著我最愛的麥克雞塊，吃第一塊，味道有點怪，雞味很重，我以為我舌頭壞掉。吃第二塊，真的很不對勁。後來請同事試吃，「硬掉了」，她說。我要你形容味道，「很正常」。好吧，從此我又遠離的麥克雞塊。( ´•̥×•̥` )

我本來就喜歡吃又酸又辣的食物，現在更是變本加厲。這陣子很喜歡吃螺螄粉，又酸又辣又臭，煮完整個家裡像被餿水桶噴到。你知道這味道有多重了。

##### 嗅覺敏感

本來就對味道蠻敏感的，但是懷孕之後，就覺得 costco 買的便宜洗碗精臭到不行，甚至洗碗後殘留在手上、空氣中的味道都讓我想吐。後來媽媽推薦我買沙拉脫，沒有味道的洗碗精，或是只有淡淡檸檬味的，才讓我重新靠近水槽。

##### 胃痛與孕吐

這是最明顯的身體改變。

通常晚餐距離睡覺至少會間隔 3 小時以上，某天睡前我胃痛到不行，那感覺像是空腹喝濃茶的鄒鄒感（當下我超恨什麼叫做胃鄒鄒，到底要我怎麼跟山地解釋），後來我跟山地說我一定要吃些東西。

吃了半根香蕉，還有一口麵包，配口水就不胃痛了。自此開始了每兩小時就一定要吃東西的模式，不吃就會胃鄒鄒。大概持續兩個禮拜。山地幫我準備了酪梨醬(guacamole)，我只要在辦公室備著切片法棍，就可以隨時補充食物。同事被我爆發的食慾嚇到。

某天下午，在我剛吃完小點時，又是一陣胃痛，但這時候比較像是嘔吐感。在座位上乾咳了一會，就去廁所抓兔子。連續幾天，都發生在下午 4-6 點之間。搭計程車也會想吐，搭到橫行的公車也會吐，這到底是孕吐還是暈車，實在難理解我兒想教育我什麼。

##### 胃脹氣

我人生從來沒有脹氣過，也不知道脹氣是什麼感覺。產檢時醫生問我有沒有脹氣，我還回答「我沒脹氣過，不知道脹氣什麼感覺」，「妳非常幸運」醫生說。

但有陣子我的胃非常不舒服，很難描述它。於是我谷歌一下跟孕婦最有關胃疾，多數是胃脹氣。起初山地不太相信是脹氣導致胃不舒服，於是我們討論要減少導致脹氣的食物，例如：鷹嘴豆、紅扁豆、豆漿、豆腐、蘋果…兩三天後這症狀就沒了。Bingo! 現在豆腐、豆漿我慢慢開始喝了，但直接吃豆還是不太敢。

##### ＊＊＊

<div align="center">
<figure style="width:60%;">
<img alt="ultrasound pic" src="https://d30ay7ug033loj.cloudfront.net/images/IMG_0431-1024x616.jpeg" width="100%"/>
<figcaption>12週又6天的小朋友，媽媽說他鼻子就是阿凸仔</figcaption>
</figure>
</div>
<br>

以上，分享一些懷孕前三個月會發生的不舒適感。其實我覺得自己蠻幸運的，因為看了 PTT 或一些媽寶論壇，不乏孕吐到產前的例子，還有要把所有食物過油的狀況。總之，懷孕路上一起加油吧。想著這也只是人生中少數的日子 (◔⊖◔)，就會好過一些了。
