# 妊娠糖尿檢查，第一次失敗了

_September 26, 2020 by [Doris](/)_

<p align="center">
<img alt="sugar water" src="https://d30ay7ug033loj.cloudfront.net/images/IMG_0641-1-1220x600.jpeg" width="100%"/>
</p>

「我是心智強健的孕婦」，不斷提醒自己。

在即將滿 26 週的時候去喝了糖水，檢查的前幾天心情很煩躁，因為網路文章都寫著喝糖水有多恐怖又多難熬，號稱產檢大魔王，加上檢查前一天 12:00 後不能喝水，怎麼可以不給喝水呢 (´-ι\_-｀) 檢查當天早上洗澡時，真想痛喝洗澡水。看看我有多焦躁。

<figure style="display: flex;justify-content: center;">
<div style="width:80%;display:flex;">
<!-- <figure style="text-align:center"> -->
<div style="text-align:center;margin:4px">
<img alt="marriage" src="https://d30ay7ug033loj.cloudfront.net/images/IMG_0641-scaled.jpeg" width="100%"/>
<figcaption>2020年4月1日愚人節，我們登記結婚了。</figcaption>
</div>
<div style="text-align:center;margin:4px">
<img alt="marriage" src="https://d30ay7ug033loj.cloudfront.net/images/IMG_0643-scaled.jpeg" width="100%"/>
<figcaption>2020年4月1日愚人節，我們登記結婚了。</figcaption>
</div>
<!-- </figure> -->
</div>
<br>
</figure>

葡萄糖氣泡水，好喝
唏哩呼嚕就喝完了
到了診所，依慣例量血壓、體重，然後就先抽了第一管血。獲得葡萄糖氣泡飲一杯，其實好喝啊！就像沒有檸檬味的雪碧。護士交代 10 分鐘內要喝完，不到 5 分鐘就咕嚕喝完，護士還多給了我大概 100ml 的水，說 5 分鐘要喝完。媽的我瞬間喝完，枯萎的媽媽即將充滿水份啦！

<div align="center">
<figure style="width:80%;">
<img src="https://media.giphy.com/media/xT1R9EgqZhv6UNSbTy/giphy.gif" alt="" width="100%">
<figcaption>source: Giphy</figcaption>
</figure>
</div>

每隔一小時抽一次血，抽了兩次我就可以放飛自我了。護士說三天後可以在 APP 看到檢查結果，醫生提醒會有三個數值，只要一個紅字就要回來看營養門診。當下我信心滿滿『絕不會出現紅字的』心想。

三天後的報告，竟然是**梅毒報告**。『醫生醫生，你沒有說有這個檢查耶』(。・・)ノ，當下想說是別人報告放錯地方嗎？噢不，是我的。很好，數字正常。第四天看到血糖報告，三個數字有兩個紅字。我馬景濤式無語問蒼天。

開始查妊娠糖尿是怎麼回事，也在第一時間跟遠在荷蘭的山地報告。不過自己邊查資訊也是愈來愈心煩，索性先回診所做營養諮詢吧。

營養諮詢大概 30 分鐘左右，營養師會先詢問你平常一天的飲食狀況、種類、份量等，這裡都必須要據實以報，以免營養師無法給你合適的建議。以下是以我某天飲食為例：

<p className="gray-color">
早餐：草莓全麥吐司一片、一顆黃金奇異果、一碗大燕麥片+無糖黑芝麻粉+牛奶、黑咖啡半杯。這樣吃完根本就像吸空氣一樣無感，後來進公司又喝了桂格喝的燕麥。週末的早餐就是雙蛋起司鮪魚全麥三明治，吃完極滿足，可以安心去健身房。
</p><p className="gray-color">
午餐：起司雞肉豆腐鍋+3/4 碗飯。或是自己煮的咖哩飯配 3/4 碗胚芽米，再加一份蛋白質，可能是雞肉或溏心蛋。
</p><p className="gray-color">
下午茶：通常會吃一包蘇打餅乾，或 2~3 口超商地瓜，或一根蒸玉米。
</p><p className="gray-color">
晚餐：燙青菜、油豆腐、豬肝湯、3/4 碗乾麵。晚餐後就不吃東西了。
</p>

<div align="center">
<figure style="width:80%;">
<img loading="lazy" src="https://d30ay7ug033loj.cloudfront.net/images/2018每日飲食指南_飲食指南.png" alt="每日飲食指南" width="100%">
<figcaption>source: <a href="https://www.medpartner.club/dietary-guide-taiwan-2018/" target="_blank" rel="noreferrer noopener" title="https://www.medpartner.club/dietary-guide-taiwan-2018/">medpartner</a>
</figcaption>
</figure>
</div>

營養師說熱量差不多，但給我最重要的指示就是「全穀雜糧、水果類、乳品類不要在同一時間吃（直指早餐），因為這三種都會讓血糖升高。最好是三取二即可，例如把水果帶到公司當餐間點心，或將早上的牛奶換成無糖豆漿，就可以避免血糖一下子升得太快。」另外就是下午茶建議我不要再吃全穀雜糧了，改成一份青菜。究竟誰的下午茶可以是一份青菜？可能是一顆大蕃茄吧 🍅

於是我之後就戰戰兢兢地把關早餐的種類，大概都可以做到三取二的方式。至於蔬菜的補充，我就會煎一顆牛蕃茄，撒一些黑胡椒跟鹽巴，就配著荷包蛋和水煮鮪魚吃。只能盡量跟上營養師的期待了。可是最近是中秋節啊~~~各種月餅都只能跟別人分食，連桂圓蛋糕都只吃 1/4，這還有天理嘛 (,,Ծ‸Ծ,, )

以上跟各位分享第一次糖水檢查失敗的學習，下一次糖水考驗就在三週後，檢查的流程是：一樣需要空腹 8 小時，抽完第一管血之後，就放風吃早餐，兩小時之後再回來抽第二管血。

「如果第二次檢查沒過，該不會要開始打胰島素了？」我緊張問營養師。她說「醫生不會這麼快就做治療，我們還是會從飲食控制開始，比較嚴格的那種。」(′ʘ⌄ʘ‵) 醬啊，那我就放心了。

推薦文章：

[能吃？不能吃？「妊娠糖尿病」全方位飲食指南!](https://www.mababy.com/knowledge-detail?id=10789) (嬰兒&母親/ 2020.07.02)

[妊娠糖尿病沒那麼可怕　醫：把握 6 要點即可控制](https://www.commonhealth.com.tw/blog/3696) (康健雜誌/ 2020.06.30)
