# How is life in Taiwan for vegans

_January 6, 2021 by [Santiago](/)_

<figure align="center">
<img alt="table full with plates of vegan food" src="https://d30ay7ug033loj.cloudfront.net/images/table-with-food-1.jpeg" width="100%"/>
</figure>

Living as a vegan in Taiwan can be challenging at times, but with a little knowledge and planning, it is possible to thrive on a plant-based diet in this vibrant and diverse country.

Taiwan has a rich and varied cuisine that is heavily influenced by Chinese, Japanese, and Southeast Asian flavors and ingredients. Traditional Taiwanese dishes often feature meat and seafood, and many dishes are cooked with lard or other animal fats. However, there are also many vegan-friendly dishes and ingredients that can be found in Taiwan, and with a little effort, it is possible to enjoy a delicious and varied plant-based diet in this fascinating country.

One of the keys to being a successful vegan in Taiwan is to familiarize yourself with the local cuisine and to learn which dishes and ingredients are suitable for a plant-based diet. Many traditional Taiwanese dishes can be easily adapted to be vegan by replacing meat and seafood with tofu, tempeh, or other plant-based proteins. For example, the popular Taiwanese dish of "dan-dan noodles" can be made vegan by substituting the ground pork with crumbled tofu, and the savory "oyster omelette" can be made with a vegan oyster sauce and a mix of diced vegetables instead of oysters.

Another important aspect of being a vegan in Taiwan is to be aware of common ingredients and cooking techniques that may not be suitable for a plant-based diet. For example, many dishes in Taiwan are cooked with lard or other animal fats, and it is important to request that these ingredients be omitted or substituted with vegetable oil when ordering dishes at restaurants. It is also important to be aware of ingredients like fish sauce, shrimp paste, and chicken broth, which are commonly used in Taiwanese cuisine and may not be suitable for vegans.

In addition to traditional Taiwanese dishes, there are also many international cuisines and plant-based options available in Taiwan. Many cities in Taiwan have a thriving food scene, and it is possible to find a wide range of vegan-friendly restaurants and cafes that offer dishes from around the world. For example, in Taipei, the capital city of Taiwan, you can find vegan versions of popular dishes from countries like India, Thailand, and Vietnam, as well as Western-style vegan dishes like burgers, salads, and pizzas.

Overall, living as a vegan in Taiwan can be challenging at times, but with a little knowledge and planning, it is possible to thrive on a plant-based diet in this vibrant and diverse country. By familiarizing yourself with the local cuisine and being aware of common ingredients and cooking techniques, you can enjoy a delicious and varied plant-based diet in Taiwan.