import {
  Box,
  Breadcrumbs,
  Chip,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { META_TITLE, posts, sections, sidebar } from "./../content/Content";
import { slugify } from "./../Tools";
import { TPost, TUrlParams } from "./../Types";
import Footer from "./Footer";
import Header from "./Header";
import Markdown from "./Markdown";
import Sidebar from "./Sidebar";

export default function BlogPost() {
  const navigate = useNavigate();
  const params = useParams<TUrlParams>();
  const post = posts.find((p) => p.slug === params.post);

  React.useEffect(() => {
    if (!post) {
      navigate("/blog");
    }
    // window.scrollTo(0, 0);
  }, []);

  if (!post) {
    return <></>;
  }

  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{getTitle(post)}</title>
        <meta name="description" content={getMetaDescription(post)} />
        <meta name="keywords" content={post.keywords.join(", ")} />
        <meta name="author" content={"OnePanFamily"} />
      </Helmet>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="OnePanFamily" sections={sections} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit" component={RouterLink} to="/">
            Home
          </Link>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to="/blog/"
          >
            Blog
          </Link>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/category/${slugify(post.categories[0])}/`}
          >
            {post.categories[0]}
          </Link>
          <Typography color="text.primary">{post.title}</Typography>
        </Breadcrumbs>
        <Box
          sx={{
            paddingTop: "24px",
          }}
        >
          {post.categories.sort().map((category) => {
            return (
              <Chip
                sx={{
                  marginRight: "12px",
                }}
                key={category}
                variant="outlined"
                clickable
                label={category}
                component={RouterLink}
                to={`/category/${slugify(category)}/`}
              />
            );
          })}
        </Box>
        <main>
          <Grid container>
            <Divider />
            <Markdown className="markdown">{post.content}</Markdown>
          </Grid>
        </main>
        <Box
          sx={{
            paddingTop: "24px",
          }}
        >
          {post.keywords.sort().map((keyword) => {
            return (
              <Chip
                sx={{
                  marginRight: "4px",
                  marginBottom: "4px",
                  fontSize: "0.7rem",
                  borderRadius: 0,
                }}
                key={keyword}
                clickable
                label={keyword.toUpperCase()}
                component={RouterLink}
                to={`/tag/${slugify(keyword)}/`}
              />
            );
          })}
        </Box>
        <Sidebar
          title={sidebar.title}
          description={sidebar.description}
          archives={sidebar.archives}
          social={sidebar.social}
        />
      </Container>
      <Footer title="" description="" />
    </ThemeProvider>
  );
}

function getTitle(post: TPost) {
  return `${post.meta.title} | ${META_TITLE}`;
}

function getMetaDescription(post: TPost) {
  return post.meta.description;
}
