# How to play Chinese chess

_November 2, 2022 by [Santiago](/)_

<figure align="center">
<img alt="chinese chess boards" src="https://d30ay7ug033loj.cloudfront.net/images/chinese-chess.jpeg" width="100%"/>
</figure>

Chinese chess, also known as xiangqi, is a strategic board game that originated in China and has been played for thousands of years. The game is believed to have originated during the Han Dynasty (206 BC – 220 AD), and it has been an important part of Chinese culture ever since.

Chinese chess is similar to the game of chess that originated in Europe, but there are some key differences between the two games. In Chinese chess, the board is divided into two equal halves by a river, and the pieces have different movements and abilities. Chinese chess also has a unique piece called the cannon, which can only capture other pieces by jumping over another piece.

Chinese chess is popular not only in China, but also in other parts of Asia, such as Vietnam and Thailand. It is played by people of all ages, and is often considered a test of intelligence and strategic thinking. The game has also been the subject of many books and movies, and has been featured in Chinese literature and art throughout history.

Chinese chess is played on a board with 10 rows and 9 columns. The board is divided into two equal halves by a river that runs through the middle of the board.

Each player has 16 pieces, including a general, two advisors, two elephants, two horses, two chariots (rooks), two cannons, and five soldiers (pawns).

The game begins with the pieces placed on the board in the following arrangement:

1. The general is placed in the middle of the back row, between the two advisors.

2. The elephants are placed in the corners of the back row, next to the advisors.

3. The horses are placed next to the elephants, on the same side of the board as the general.

4. The chariots are placed on the outside of the horses, on the same side of the board as the general.

5. The cannons are placed in the middle of the back row, between the horses and the chariots.

6. The soldiers are placed in the front row, in the five columns between the cannons and the chariots.

7. The players take turns moving their pieces according to the following rules:

8. The general can move one square horizontally or vertically. It cannot leave the palace, which is a 3x3 area in the center of the back row.

9. The advisors can move one square diagonally. They cannot leave the palace.

10. The elephants can move two squares horizontally or vertically, but they cannot jump over other pieces. They cannot cross the river.

11. The horses can move one square horizontally or vertically, and then one square diagonally in the same direction. They cannot jump over other pieces.

12. The chariots can move any number of squares horizontally or vertically. They cannot jump over other pieces.

13. The cannons can move any number of squares horizontally or vertically. They can only capture an opponent's piece by jumping over another piece, which can be a friendly or enemy piece.

14. The soldiers can move forward one square, but they cannot move backwards. They can only capture an opponent's piece by moving diagonally. They can cross the river and become stronger when they reach the other side.

The game ends when one player captures the opponent's general, or when the opponent's general is unable to move and is in danger of being captured.

These are just the basic rules for playing Chinese chess. There are many more advanced rules and strategies involved in the game, and it can take many years to master.