import { Breadcrumbs, Link, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  categoryDescription,
  META_DESCRIPTION,
  META_TITLE,
  posts,
  sections,
} from "../content/Content";
import { slugify, toTitleCase } from "../Tools";
import { TCategory, TPost } from "../Types";
import FeaturedPost from "./FeaturedPost";
import Footer from "./Footer";
import Header from "./Header";
import MainFeaturedPost from "./MainFeaturedPost";

const theme = createTheme();

export default function Blog() {
  const { categoryId, tagId } = useParams<{
    categoryId: TCategory;
    tagId: string;
  }>();

  const filteredPosts = getFilterPosts(posts, categoryId, tagId);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{getTitle(categoryId, tagId)}</title>
        <meta
          name="description"
          content={getMetaDescription(categoryId, tagId)}
        />
        <meta name="author" content={"OnePanFamily"} />
      </Helmet>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="OnePanFamily" sections={sections} />
        {categoryId && (
          <CategoryBreadcrumb categoryId={categoryId}></CategoryBreadcrumb>
        )}
        <main>
          {filteredPosts.length > 0 && (
            <MainFeaturedPost
              post={
                filteredPosts.find(
                  (p) => p.slug === "我的第一胎，出生只花了30分鐘"
                ) ?? filteredPosts[0]
              }
            />
          )}
          <Grid container spacing={4}>
            {filteredPosts
              .filter((p) => p.slug !== "我的第一胎，出生只花了30分鐘")
              .map((post, index) => (
                <FeaturedPost key={index} post={post} />
              ))}
          </Grid>
        </main>
      </Container>
      <Footer title="" description="" />
    </ThemeProvider>
  );
}

function CategoryBreadcrumb(props: { categoryId: TCategory }) {
  const categoryTitle = categoryDescription[props.categoryId].title;
  return (
    <>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          to="/blog/"
        >
          Blog
        </Link>
        <Typography color="text.primary">{categoryTitle}</Typography>
      </Breadcrumbs>
      <Typography
        variant={"h3"}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginTop: "24px",
          paddingBottom: "12px",
        }}
      >
        {categoryTitle}
      </Typography>
    </>
  );
}

function getTitle(
  categoryId: TCategory | undefined,
  tagId: string | undefined
) {
  if (!categoryId && !tagId) {
    return `Blog - ${META_TITLE}`;
  }
  if (categoryId) {
    return `${categoryDescription[categoryId].title} - ${META_TITLE}`;
  }
  if (tagId) {
    return `${toTitleCase(tagId)} - ${META_TITLE}`;
  }
  return `Blog - ${META_TITLE}`;
}

function getMetaDescription(
  categoryId: TCategory | undefined,
  tagId: string | undefined
) {
  if (!categoryId && !tagId) {
    return META_DESCRIPTION;
  }
  if (categoryId) {
    return categoryDescription[categoryId].description ?? META_DESCRIPTION;
  }
  return META_DESCRIPTION;
}

function getFilterPosts(
  posts: ReadonlyArray<TPost>,
  categoryId: TCategory | undefined,
  tagId: string | undefined
) {
  if (!categoryId && !tagId) {
    return posts;
  }

  let filteredPosts: TPost[] = [];

  if (categoryId) {
    const slugCategoryId = slugify(categoryId);
    filteredPosts = [...posts].filter((p) =>
      p.categories.map((c) => slugify(c)).includes(slugCategoryId)
    );
  }
  if (tagId) {
    const slugTagId = slugify(tagId);
    filteredPosts = [...posts].filter((p) =>
      p.keywords.map((c) => slugify(c)).includes(slugTagId)
    );
  }

  if (filteredPosts.length < 3) {
    return [...filteredPosts, ...posts];
  }
  return filteredPosts;
}
