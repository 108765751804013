import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YoutubeIcon from "@mui/icons-material/YouTube";

import { TCategory, TPost, TSection } from "./../Types";
import {
  post1,
  post10,
  post11,
  post12,
  post15,
  post16,
  post17,
  post19,
  post2,
  post20,
  post21,
  post22,
  post23,
  post24,
  post25,
  post26,
  post27,
  post28,
  post29,
  post3,
  post30,
  post31,
  post32,
  post33,
  post4,
  post5,
  post6,
  post7,
  post8,
  post9,
} from "./Files";

export const META_TITLE = "OnePanFamily";
export const META_DESCRIPTION =
  "We met on the Internet, we fell in love in Paris, and now we lived between the Netherlands, Spain and Taiwan. LDR advise, travel recommendations, and personal stories.";

export const sections: ReadonlyArray<TSection> = [
  { title: "Home", url: "/" },
  { title: "Blog", url: "/blog/" },
  { title: "Contact", url: "/contact/" },
];

export const posts: ReadonlyArray<TPost> = [
  {
    slug: "how-to-exchange-your-drivers-license-in-taiwan",
    title: "How to exchange your driver's license in Taiwan",
    date: {
      year: 2022,
      month: 12,
      day: 15,
    },
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/car_mountains_view.jpg",
    imageLabel: "car with mountains in the background",
    linkText: "Continue reading...",
    content: post32,
    keywords: [
      "taiwan",
      "exchange",
      "driving license",
      "driving license requirements",
      "driving test",
      "driving schools",
      "types of driving licences",
      "change of foreign license",
    ],
    categories: ["Taiwan", "Travel"],
    description:
      "If you are considering living in Taiwan for a long period of time and want to be able to use a car or motorcycle, you may be able to exchange your home country driver's license for a local driver's license without having to pass a driving test.",
    meta: {
      title: "How to exchange your driver's license in Taiwan",
      description:
        "If you are considering living in Taiwan for a long period of time and want to be able to use a car or motorcycle, you may be able to exchange your home country driver's license for a local driver's license without having to pass a driving test.",
    },
  },
  {
    slug: "como-canjear-el-permiso-de-conducir-en-taiwan",
    title: "Cómo canjear el permiso de conducir en Taiwán",
    date: {
      year: 2022,
      month: 12,
      day: 15,
    },
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/car_mountains_view.jpg",
    imageLabel: "coche con montanas de fonto",
    linkText: "Continue reading...",
    content: post31,
    keywords: [
      "taiwan",
      "canjear",
      "carnet de conducir",
      "requisitos carnet de conducir",
      "test de conducción",
      "autoescuelas",
      "tipos de permisos de conducción",
      "cambio de licencia extranjera",
    ],
    categories: ["Taiwan", "Travel"],
    description:
      "Si te estás planteando vivir en Taiwán por un periodo largo de tiempo y quieres poder usar un coche o una motocicleta es probable que puedas canjear el permiso de conducir de tu país de origen por el permiso local sin tener que pasar un examen de conducir.",
    meta: {
      title: "Cómo canjear el permiso de conducir en Taiwán",
      description:
        "Si te estás planteando vivir en Taiwán por un periodo largo de tiempo y quieres poder usar un coche o una motocicleta es probable que puedas canjear el permiso de conducir de tu país de origen por el permiso local sin tener que pasar un examen de conducir.",
    },
  },
  {
    slug: "how-to-boost-your-seo-with-these-essential-tips",
    title: "How to boost your SEO with these essential tips",
    date: {
      year: 2022,
      month: 12,
      day: 7,
    },
    image: "https://d30ay7ug033loj.cloudfront.net/images/source-code.jpeg",
    imageLabel: "screens with source code",
    linkText: "Continue reading...",
    content: post27,
    keywords: [
      "taiwan",
      "SEO",
      "Search engine optimization",
      "Keyword research",
      "On-page optimization",
      "Quality content",
      "Internal linking",
      "External linking",
      "User experience",
      "Social media promotion",
      "Analytics tracking",
      "Local SEO",
      "Mobile optimization",
      "Technical SEO",
      "Off-page optimization",
      "Link building",
      "SERP",
      "Algorithms",
      "Keyword density",
      "Meta tags",
    ],
    categories: ["Taiwan", "Work"],
    description:
      "SEO, or search engine optimization, is the process of improving the visibility of a website or web page on search engines. This is typically done through a combination of on-page and off-page strategies. Some common SEO optimization strategies for a blog include:",
    meta: {
      title: "How to boost your SEO with these essential tips",
      description:
        "SEO, or search engine optimization, is the process of improving the visibility of a website or web page on search engines. This is typically done through a combination of on-page and off-page strategies. Some common SEO optimization strategies for a blog include:",
    },
  },
  {
    slug: "hiking-in-taipei",
    title: "Hiking in Taipei",
    date: {
      year: 2022,
      month: 12,
      day: 3,
    },
    image: "https://d30ay7ug033loj.cloudfront.net/images/hiking-taipei-1.jpg",
    imageLabel: "hiking rout in taipei",
    linkText: "Continue reading...",
    content: post17,
    keywords: [
      "taiwan",
      "hiking",
      "senderismo",
      "mountain",
      "nature",
      "forest",
      "taipei",
    ],
    categories: ["Taiwan"],
    description:
      "Taipei is a city full of surprises and hidden gems, and one of the best ways to discover its beauty is by going for a hike. The city is surrounded by mountains and offers a wide range of hiking trails, from easy and leisurely to challenging and strenuous. Whether you are a beginner or an experienced hiker, Taipei has something for everyone.",
    meta: {
      title: "Hiking in Taipei",
      description:
        "Taipei is a city full of surprises and hidden gems, and one of the best ways to discover its beauty is by going for a hike. The city is surrounded by mountains and offers a wide range of hiking trails, from easy and leisurely to challenging and strenuous. Whether you are a beginner or an experienced hiker, Taipei has something for everyone.",
    },
  },
  {
    slug: "how-to-play-chinese-chess",
    title: "How to play Chinese chess",
    date: {
      year: 2022,
      month: 11,
      day: 2,
    },
    image: "https://d30ay7ug033loj.cloudfront.net/images/chinese-chess.jpeg",
    imageLabel: "chinese chess boards",
    linkText: "Continue reading...",
    content: post24,
    keywords: [
      "taiwan",
      "chess",
      "chinese",
      "board game",
      "strategy",
      "tactics",
      "tournament",
      "history of chess",
    ],
    categories: ["Taiwan"],
    description:
      "Chinese chess, also known as xiangqi, is a strategic board game that originated in China and has been played for thousands of years. The game is believed to have originated during the Han Dynasty (206 BC – 220 AD), and it has been an important part of Chinese culture ever since.",
    meta: {
      title: "How to play Chinese chess",
      description:
        "Chinese chess, also known as xiangqi, is a strategic board game that originated in China and has been played for thousands of years. The game is believed to have originated during the Han Dynasty (206 BC – 220 AD), and it has been an important part of Chinese culture ever since.",
    },
  },
  {
    slug: "what-is-dalle-and-how-does-it-work",
    title: "What is DALL·E an how does it work",
    date: {
      year: 2022,
      month: 10,
      day: 16,
    },
    image: "https://d30ay7ug033loj.cloudfront.net/images/art.jpeg",
    imageLabel: "ai generated image",
    linkText: "Continue reading...",
    content: post23,
    keywords: [
      "taiwan",
      "ai",
      "work",
      "machine learning",
      "neural networks",
      "deep learning",
      "nlp",
      "taipei",
    ],
    categories: ["Taiwan", "Work"],
    description:
      "DALL·E is a large language model trained by OpenAI that is capable of generating images from text descriptions. It is named after the artist Salvador Dali and the Pixar character WALL·E, and is designed to combine the capabilities of language models with the ability to generate images.",
    meta: {
      title: "What is DALL·E an how does it work",
      description:
        "DALL·E is a large language model trained by OpenAI that is capable of generating images from text descriptions. It is named after the artist Salvador Dali and the Pixar character WALL·E, and is designed to combine the capabilities of language models with the ability to generate images.",
    },
  },
  {
    slug: "explora-taiwan-en-movimiento-opciones-para-hacer-deporte-en-la-isla",
    title:
      "Explora Taiwan en movimiento: opciones para hacer deporte en la isla",
    date: {
      year: 2022,
      month: 9,
      day: 10,
    },
    image: "https://d30ay7ug033loj.cloudfront.net/images/outdoor-gym-5.jpg",
    imageLabel: "outdoor gym in the mountains",
    linkText: "Continue reading...",
    content: post30,
    keywords: [
      "taiwan",
      "deporte",
      "actividad",
      "aire libre",
      "naturaleza",
      "sports",
      "indoor",
      "outdoor",
      "gimnasion",
      "ejercicio",
      "taipei",
    ],
    categories: ["Taiwan", "Sports"],
    description:
      "En Taiwán hay muchas opciones para hacer deporte. Algunas de las actividades deportivas más populares en Taiwan incluyen caminar, correr, ciclismo, yoga, baile, gimnasia, natación, artes marciales, senderismo y escalada.",
    meta: {
      title:
        "Explora Taiwan en movimiento: opciones para hacer deporte en la isla",
      description:
        "En Taiwán hay muchas opciones para hacer deporte. Algunas de las actividades deportivas más populares en Taiwan incluyen caminar, correr, ciclismo, yoga, baile, gimnasia, natación, artes marciales, senderismo y escalada.",
    },
  },
  {
    slug: "remote-work-in-taiwan",
    title: "Remote work in Taiwan",
    date: {
      year: 2022,
      month: 4,
      day: 6,
    },
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/horizon-field-remote.jpg",
    imageLabel: "field with grass and big horizon",
    linkText: "Continue reading...",
    content: post22,
    keywords: [
      "taiwan",
      "remote work",
      "work",
      "freelance",
      "nature",
      "remote jobs",
      "telecommuting",
      "virtual work",
      "online work",
      "work-from-home",
      "flexible work",
      "taipei",
    ],
    categories: ["Taiwan", "Work"],
    description:
      "As someone who has been working remotely from Taiwan for several years, I can attest that it can be a great experience. Taiwan is a beautiful and vibrant country, with a rich culture and a thriving economy, and it offers many benefits for remote workers.",
    meta: {
      title: "Remote work in Taiwan",
      description:
        "As someone who has been working remotely from Taiwan for several years, I can attest that it can be a great experience. Taiwan is a beautiful and vibrant country, with a rich culture and a thriving economy, and it offers many benefits for remote workers.",
    },
  },
  {
    slug: "how-to-pay-taxes-in-taiwan",
    title: "Taxes in Taiwan",
    date: {
      year: 2021,
      month: 12,
      day: 23,
    },
    image: "https://d30ay7ug033loj.cloudfront.net/images/2-euro-coin.jpeg",
    imageLabel: "two euro coin",
    linkText: "Continue reading...",
    content: post16,
    keywords: ["taiwan", "impuestos", "taxes", "rent", "ingresos", "income"],
    categories: ["Taiwan"],
    description:
      "When you live and work in different countries, one of the things you must take into account is how to manage the payment of taxes. In this post I want to share my particular process in case it can help someone who is in the same situation.",
    meta: {
      title: "Taxes in Taiwan",
      description:
        "When you live and work in different countries, one of the things you must take into account is how to manage the payment of taxes. In this post I want to share my particular process in case it can help someone who is in the same situation.",
    },
  },
  {
    slug: "como-pagar-impuestos-en-taiwan",
    title: "Impuestos en Taiwán",
    date: {
      year: 2021,
      month: 12,
      day: 23,
    },
    image: "https://d30ay7ug033loj.cloudfront.net/images/2-euro-coin.jpeg",
    imageLabel: "moneda de dos euros",
    linkText: "Sigue leyendo...",
    content: post15,
    keywords: ["taiwan", "impuestos", "taxes", "rent", "ingresos", "income"],
    categories: ["Taiwan"],
    description:
      "Cuando vives y trabajas en diferentes países una de las cosas que debes tener en cuenta es cómo gestionar el pago de impuestos. En este post quiero compartir mi caso particular por si puede ayudar a alguien que se encuentre en la misma situación.",
    meta: {
      title: "Impuestos en Taiwán",
      description:
        "Cuando vives y trabajas en diferentes países una de las cosas que debes tener en cuenta es cómo gestionar el pago de impuestos. En este post quiero compartir mi caso particular por si puede ayudar a alguien que se encuentre en la misma situación.",
    },
  },
  // DRAFT
  // {
  //   slug: "四款電動吸乳器比較",
  //   title: "四款電動吸乳器比較",
  //   date: {
  //     year: 2021,
  //     month: 12,
  //     day: 9,
  //   },
  //   image: "",
  //   imageLabel: "",
  //   linkText: "Continue reading...",
  //   content: post14,
  //   keywords: [],
  //   categories: [],
  //   description:
  //     "生產前，就聽說吸乳器很重要，雖然自己上網看了一輪，看了別人的心得文，但我最終還是沒下決定，也還不確定自己是否有足夠母奶，或是我可以親餵嗎？太多未知，最後盤算著從月子中心離開前再買就好了。後來我覺得這個決定很正確。 木生的月中非常貼心，每間房內備有3款電動吸乳器，我的房內有：combi自然吸韻、philips Avent、美樂Freestyle。一開始護士推薦我用combi，他們說吸力比較強，適合…",
  //   meta: {
  //     title: "四款電動吸乳器比較",
  //     description:
  //       "生產前，就聽說吸乳器很重要，雖然自己上網看了一輪，看了別人的心得文，但我最終還是沒下決定，也還不確定自己是否有足夠母奶，或是我可以親餵嗎？太多未知，最後盤算著從月子中心離開前再買就好了。後來我覺得這個決定很正確。 木生的月中非常貼心，每間房內備有3款電動吸乳器，我的房內有：combi自然吸韻、philips Avent、美樂Freestyle。一開始護士推薦我用combi，他們說吸力比較強，適合…",
  //   },
  // },
  // DRAFT
  {
    slug: "how-your-life-changes-when-you-become-a-dad",
    title: "How your life changes when you become a dad",
    date: {
      year: 2021,
      month: 3,
      day: 12,
    },
    image: "https://d30ay7ug033loj.cloudfront.net/images/son-and-father.jpeg",
    imageLabel: "father with a son in his arms",
    linkText: "Continue reading...",
    content: post19,
    keywords: [
      "taiwan",
      "father",
      "dad",
      "first son",
      "life",
      "change",
      "challenges",
      "born",
    ],
    categories: ["Taiwan"],
    description:
      "Becoming a father for the first time is a life-changing experience that brings with it a host of new responsibilities and challenges.",
    meta: {
      title: "How your life changes when you become a dad",
      description:
        "Becoming a father for the first time is a life-changing experience that brings with it a host of new responsibilities and challenges.",
    },
  },
  {
    slug: "我的第一胎，出生只花了30分鐘",
    title: "我的第一胎，出生只花了30分鐘",
    date: {
      year: 2021,
      month: 3,
      day: 8,
    },
    description:
      "在最後一次產檢，也就是39週的時候，醫生說因為胎頭還不夠下沉，判斷寶寶應該還沒這麼快出來，當天是2020年12月18日星期五的早診。醫生說他週日、週一會休假遠行，所以趕得上接生。叫我不用擔心。",
    image: "https://d30ay7ug033loj.cloudfront.net/images/my-first-baby.jpeg",
    imageLabel: "西班牙戶口名簿",
    linkText: "Continue reading…",
    content: post12,
    keywords: [
      "結婚登記",
      "西班牙商務辦事處",
      "西班牙戶口名簿",
      "西班牙駐馬德里大使館",
    ],
    categories: ["Marriage"],
    meta: {
      title: "我的第一胎，出生只花了30分鐘",
      description:
        "在最後一次產檢，也就是39週的時候，醫生說因為胎頭還不夠下沉，判斷寶寶應該還沒這麼快出來，當天是2020年12月18日星期五的早診。醫生說他週日、週一會休假遠行，所以趕得上接生。叫我不用擔心。 這天產檢Edu寶寶比了OK 週五產檢後，醫生提醒我最後幾天要多動，幫助胎頭下沉，所以山地逼迫我從長安東路散步到忠孝復興站，吃飽後又繼續散步回家，手錶紀錄當天走了近11,000步。 ( ಠ __ ಠ ; )…",
    },
  },
  {
    slug: "how-is-life-in-taiwan-for-vegans",
    title: "How is life in Taiwan for vegans",
    date: {
      year: 2021,
      month: 1,
      day: 6,
    },
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/table-with-food-1.jpeg",
    imageLabel: "table with vegan food",
    linkText: "Continue reading...",
    content: post21,
    keywords: [
      "taiwan",
      "food",
      "flavour",
      "cooking",
      "gastronomy",
      "taste",
      "tasty",
      "vegan",
      "vegetarian",
      "plant-based",
      "health",
    ],
    categories: ["Taiwan", "Food"],
    description:
      "Living as a vegan in Taiwan can be challenging at times, but with a little knowledge and planning, it is possible to thrive on a plant-based diet in this vibrant and diverse country.",
    meta: {
      title: "How is life in Taiwan for vegans",
      description:
        "Living as a vegan in Taiwan can be challenging at times, but with a little knowledge and planning, it is possible to thrive on a plant-based diet in this vibrant and diverse country.",
    },
  },
  {
    slug: "the-food-culture-of-taiwan",
    title: "The food culture of Taiwan",
    date: {
      year: 2020,
      month: 11,
      day: 12,
    },
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/table-with-food-3.jpeg",
    imageLabel: "table with food",
    linkText: "Continue reading...",
    content: post20,
    keywords: [
      "taiwan",
      "food",
      "flavour",
      "cooking",
      "gastronomy",
      "taste",
      "tasty",
    ],
    categories: ["Taiwan", "Food"],
    description:
      "Taiwanese cuisine is a unique blend of flavors, textures, and cooking techniques that have been influenced by various cultures throughout the country's history. From savory street food to elegant dining experiences, Taiwan offers a diverse range of culinary delights that will delight the senses and satisfy even the most discerning foodies.",
    meta: {
      title: "The food culture of Taiwan",
      description:
        "Taiwanese cuisine is a unique blend of flavors, textures, and cooking techniques that have been influenced by various cultures throughout the country's history. From savory street food to elegant dining experiences, Taiwan offers a diverse range of culinary delights that will delight the senses and satisfy even the most discerning foodies.",
    },
  },
  {
    slug: "我在第二孕期的身體改變",
    title: "我在第二孕期的身體改變",
    date: {
      year: 2020,
      month: 10,
      day: 11,
    },
    description:
      "其實第二孕期（14~28週）算是相對舒適的一個階段，少了剛懷孕時的慌張感，更習慣和寶寶共處，也逐漸恢復孕前的運動習慣，走步道、打壁球、健身房，還有下班走路回家。整體來說，心理更加踏實，那些懷疑自己能否承擔媽媽責任的心情都過了。",
    image: "https://d30ay7ug033loj.cloudfront.net/images/pillow_bed.jpeg",
    imageLabel: "pillow on bed",
    linkText: "Continue reading...",
    content: post33,
    keywords: [
      "第二孕期",
      "子癲前症",
      "懷孕鼻過敏",
      "胎動",
      "肚皮癢",
      "妊娠油",
      "妊娠乳液",
      "月亮枕",
    ],
    categories: ["Pregnancy"],
    meta: {
      title: "我在第二孕期的身體改變",
      description:
        "其實第二孕期（14~28週）算是相對舒適的一個階段，少了剛懷孕時的慌張感，更習慣和寶寶共處，也逐漸恢復孕前的運動習慣，走步道、打壁球、健身房，還有下班走路回家。整體來說，心理更加踏實，那些懷疑自己能否承擔媽媽責任的心情都過了。",
    },
  },
  {
    slug: "台灣人在西班牙登記結婚",
    title: "台灣人要在西班牙登記結婚啦",
    date: {
      year: 2020,
      month: 10,
      day: 6,
    },
    description:
      "這篇文章是寫給已經在台灣登記結婚後，婚姻還沒因為流程而破局的伴侶們。 (ﾟ∀。) 趁著自己還有記憶的時候，趕快跟你們分享，不然要是再跑一次的話也會很慌張。（揪竟為何要再跑一次…",
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/IMG_0789-scaled-e1601978920748-1220x600.jpeg",
    imageLabel: "西班牙戶口名簿",
    linkText: "Continue reading…",
    content: post11,
    keywords: [
      "結婚登記",
      "西班牙商務辦事處",
      "西班牙戶口名簿",
      "西班牙駐馬德里大使館",
    ],
    categories: ["Marriage"],
    meta: {
      title: "台灣人準備在西班牙登記結婚的文件",
      description:
        "異國婚姻都需要很多耐性，第一個關卡就是結婚登記的文件準備。看了這篇文章會讓你們的異國婚姻好過一些",
    },
  },
  {
    slug: "妊娠糖尿檢查，第一次失敗了",
    title: "妊娠糖尿檢查，第一次失敗了",
    date: {
      year: 2020,
      month: 9,
      day: 26,
    },
    description:
      "在即將滿26週的時候去喝了糖水，檢查的前幾天心情很煩躁，因為網路文章都寫著喝糖水有多恐怖又多難熬，號稱產檢大魔王，加上檢查前一天12:00後不能喝水，怎麼可以不給喝水呢 (´-ι_-｀) 檢查當天早上洗澡時，真想痛喝洗澡水。看看我有多焦躁。",
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/IMG_0641-1-1220x600.jpeg",
    imageLabel: "sugar water",
    linkText: "Continue reading…",
    content: post10,
    keywords: ["喝糖水", "妊娠糖尿檢查", "妊娠糖尿病", "懷孕", "血糖"],
    categories: ["Pregnancy", "Taiwan"],

    meta: {
      title: "妊娠糖尿檢查失敗了",
      description:
        "分享妊娠糖尿檢查的心情，還有營養師針對個人影師的建議，期待下一次檢查的結果",
    },
  },
  {
    slug: "懷孕了，何時該跟主管報備？",
    title: "懷孕了，何時該跟主管報備？",
    date: {
      year: 2020,
      month: 9,
      day: 24,
    },
    description:
      "這是一個大哉問，因為跟產業、職務內容、公司文化都有很大的關係。同事說他的朋友在會計事務所工作，懷孕到六個月後遮不住了，才讓部門的人知道。我媽告誡我「千萬不要太早跟主管說，到時候你的工作受影響…」。看到批踢的媽寶版討論產檢假/育嬰假被刁難，或孕產期相關的權益被剝奪等，覺得我媽會擔心也是正常。",
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/family-work-balance.jpeg",
    imageLabel: "work live balance",
    linkText: "Continue reading…",
    content: post9,
    keywords: ["懷孕", "懷孕歧視", "職場歧視"],
    categories: ["Pregnancy", "Taiwan"],
    meta: {
      title: "懷孕該何時告知主管",
      description:
        "職場女性懷孕時總是驚慌失措，不知道該如何或何時跟同事分享喜訊，也不確定什麼時候跟主管報備是最好的...",
    },
  },
  {
    slug: "how-a-spainish-partner-got-married-in-taiwan",
    title: "西班牙配偶在台灣登記結婚的瑣事",
    date: {
      year: 2020,
      month: 8,
      day: 1,
    },
    description:
      "這篇是教學文，因為山地已經寫了英文、西文各一篇，但我覺得有必要用中文交代一下申請文件的過程，因為Google到的文章都已經蠻久了，不如就來幫助跟我一樣因為看不懂申請流程差點放棄異國婚姻的台灣人吧 ( ´Д`)y━･~~",
    image: "https://d30ay7ug033loj.cloudfront.net/images/cropped.jpeg",
    imageLabel: "image of a document",
    linkText: "Continue reading…",
    content: post8,
    keywords: ["台灣", "外籍配偶", "結婚登記", "西班牙"],
    categories: ["Marriage"],
    meta: {
      title: "西班牙配偶在台灣登記結婚",
      description:
        "要幫助西班牙配偶在台灣登記結婚的準備文件，包含出生證明和婚姻狀況證明，但步驟蠻煩瑣的，看完會對你很有幫助的",
    },
  },
  {
    slug: "mosquitos-en-taiwan",
    title: "Mosquitos en Taiwán",
    date: {
      year: 2020,
      month: 7,
      day: 15,
    },
    description:
      "Si eres una de esas personas cuya sangre los mosquitos adoran, la única que recibe las picaduras aunque estés rodeada de más gente, y que no te importaría que se extinguiesen por completo de este planeta, yo te saludo.",
    image: "https://d30ay7ug033loj.cloudfront.net/images/taipei-city-view.jpeg",
    imageLabel: "taipei city",
    linkText: "Continue reading…",
    content: post7,
    keywords: [
      "antimosquitos",
      "familia",
      "goshare",
      "granos",
      "hiking",
      "hualien",
      "mosquitos",
      "naturaleza",
      "nature parks",
      "parques",
      "picadura",
      "repelente",
      "taipei",
      "taiwan",
      "taroko",
      "trail",
      "viajar",
      "youbike",
    ],
    categories: ["Taiwan"],
    meta: {
      title: "Mosquitos en Taiwán | Mosquitoes in Taiwan",
      description:
        "Mis desventuras con los mosquitos en el maravilloso país de Taiwán.",
    },
  },
  {
    slug: "how-to-get-married-in-taiwan",
    title: "How to get married in Taiwan",
    date: {
      year: 2020,
      month: 6,
      day: 24,
    },
    description:
      "If you are reading this post you will surely find yourself in the same situation I was in a few years ago, you have fallen in love with a woman or a man from Taiwan, you have come to visit this wonderful country and now you are clear that you want to get married and build a future together. My sincere congratulations! In this post I am try to explain what is the process to get married in Taiwan and what documents would you need.",
    image: "https://d30ay7ug033loj.cloudfront.net/images/weding-picture.jpeg",
    imageLabel: "First picture after getting married",
    linkText: "Continue reading…",
    content: post6,
    keywords: [
      "boda",
      "casarse",
      "documentos",
      "documents",
      "marriage",
      "matrimonio",
      "merried",
      "taiwan",
      "wedding",
    ],
    categories: ["Taiwan"],
    meta: {
      title: "Cómo casarse en Taiwán | How to get married in Taiwan",
      description:
        "Process and documentation to get married in Taiwan. | Proceso y documentación para casarse en Taiwán",
    },
  },
  {
    slug: "como-casarse-en-taiwan",
    title: "Cómo casarse en Taiwán",
    date: {
      year: 2020,
      month: 6,
      day: 22,
    },
    description:
      "Si estas leyendo este post seguro que te encuentras en la misma situación en la que yo estaba hace unos años, te has enamorado de una mujer o un hombre de Taiwán, has venido a visitar este maravilloso país y ahora tienes claro que os queréis casar y construir un futuro juntos.",
    image: "https://d30ay7ug033loj.cloudfront.net/images/weding-picture.jpeg",
    imageLabel: "Primera foro de la boda",
    linkText: "Continue reading…",
    content: post5,
    keywords: [
      "boda",
      "casarse",
      "documentos",
      "documents",
      "marriage",
      "matrimonio",
      "merried",
      "taiwan",
      "wedding",
    ],
    categories: ["Taiwan"],
    meta: {
      title: "Cómo casarse en Taiwán | How to get married in Taiwan",
      description:
        "Proceso y documentación para casarse en Taiwán. | Process and documentation to get married in Taiwan.",
    },
  },
  {
    slug: "what-happened-to-my-body-during-1st-trimester",
    title: "懷孕後，每天都在重新認識自己身體",
    date: {
      year: 2020,
      month: 6,
      day: 20,
    },
    description: "此刻的我大概懷孕13週。",
    image: "https://d30ay7ug033loj.cloudfront.net/images/baby.jpeg",
    imageLabel: "ultrasound pic",
    linkText: "Continue reading...",
    content: post4,
    keywords: ["懷孕", "懷孕不適", "混血寶寶", "第一孕期"],
    categories: ["Pregnancy"],
    meta: {
      title: "懷孕初期的身體不適",
      description: "分享高齡產婦在懷孕初期的身體改變和症狀，和大家交流意見",
    },
  },
  {
    slug: "we-are-expecting",
    title: "我懷孕了",
    date: {
      year: 2020,
      month: 5,
      day: 22,
    },
    description:
      "As a resident of Taiwan, I have come to rely heavily on convenience stores for many of my daily needs. These small, convenient stores can be found on nearly every corner in cities and towns throughout the country, and they offer a wide range of products and services that make life easier for both locals and travelers.",
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/IMG_9980-1136x600.jpeg",
    imageLabel: "ultrasound pic",
    linkText: "Continue reading...",
    content: post3,
    keywords: ["hyperthyroidism", "mom-to-be", "pregnancy", "pregnant"],
    categories: ["Pregnancy"],
    meta: {
      title: "高齡懷孕心情",
      description:
        "發現懷孕的心情，高齡而且又有甲狀腺亢進，心情很慌張，但只好見招拆招了。",
    },
  },
  {
    slug: "the-benefits-of-using-a-delivery-service-in-taiwan",
    title: "The benefits of using a delivery service in Taiwan",
    date: {
      year: 2020,
      month: 5,
      day: 21,
    },
    description:
      "Taiwan has a thriving food culture, with endless options for delicious meals. In recent years, delivery food services have become increasingly popular in Taiwan, making it easier than ever to enjoy a wide variety of tasty dishes from the comfort of your own home.",
    image: "https://d30ay7ug033loj.cloudfront.net/images/delivery-service.jpeg",
    imageLabel: "delivery services motorbike",
    linkText: "Continue reading...",
    content: post26,
    keywords: [
      "taiwan",
      "taipei",
      "delivery",
      "delivery service",
      "package delivery",
      "courier service",
      "express delivery",
      "overnight delivery",
      "home delivery",
      "tracking",
      "delivery times",
      "shipping rates",
      "pick-up options",
      "customer service",
      "packaging",
      "green delivery",
      "last mile delivery",
      "crowdsourced delivery",
    ],
    categories: ["Taiwan", "Food"],
    meta: {
      title: "The Benefits of Using a Delivery Service in Taiwan",
      description:
        "Taiwan has a thriving food culture, with endless options for delicious meals. In recent years, delivery food services have become increasingly popular in Taiwan, making it easier than ever to enjoy a wide variety of tasty dishes from the comfort of your own home.",
    },
  },
  {
    slug: "convenience-stores-in-taiwan",
    title: "Convenience stores in Taiwan",
    date: {
      year: 2020,
      month: 4,
      day: 11,
    },
    description:
      "As a resident of Taiwan, I have come to rely heavily on convenience stores for many of my daily needs. These small, convenient stores can be found on nearly every corner in cities and towns throughout the country, and they offer a wide range of products and services that make life easier for both locals and travelers.",
    image: "https://d30ay7ug033loj.cloudfront.net/images/seven-eleven.jpeg",
    imageLabel: "convenience store",
    linkText: "Continue reading...",
    content: post25,
    keywords: [
      "Convenience store",
      "24-hour store",
      "Snacks",
      "Beverages",
      "Groceries",
      "ATM",
      "Gas station",
      "Lottery tickets",
      "Magazine rack",
      "Prepaid phone cards",
      "Single serve meals",
      "Milk",
      "Bread",
      "Ice cream",
      "Slurpee",
      "Energy drinks",
      "Soft drinks",
      "Chips",
      "Candy",
    ],
    categories: ["Taiwan", "Food"],
    meta: {
      title: "Convenience stores in Taiwan",
      description:
        "As a resident of Taiwan, I have come to rely heavily on convenience stores for many of my daily needs. These small, convenient stores can be found on nearly every corner in cities and towns throughout the country, and they offer a wide range of products and services that make life easier for both locals and travelers.",
    },
  },
  {
    slug: "when-is-the-best-time-to-travel-to-taiwan",
    title: "When is the best time to travel to Taiwan",
    date: {
      year: 2019,
      month: 11,
      day: 15,
    },
    description:
      "In general, Taiwan has a subtropical climate with hot and humid summers and mild winters. The typhoon season typically runs from June to October, which can affect travel plans and activities.",
    image: "https://d30ay7ug033loj.cloudfront.net/images/taroko-park.jpg",
    imageLabel: "rain forest mountain taiwan",
    linkText: "Continue reading…",
    content: post29,
    keywords: [
      "taiwan",
      "weather",
      "travel",
      "tips",
      "travel",
      "hacks",
      "budget",
      "packing tips",
      "itinerary planning",
      "travel safety",
      "solo travel",
      "adventure",
    ],
    categories: ["Taiwan"],
    meta: {
      title: "When is the best time to travel to Taiwan.",
      description:
        "In general, Taiwan has a subtropical climate with hot and humid summers and mild winters. The typhoon season typically runs from June to October, which can affect travel plans and activities.",
    },
  },
  {
    slug: "yo-en-paris-y-tu-en-le-mont-saint-michel",
    title: "Yo en París y tú en Le Mont Saint-Michel",
    date: {
      year: 2019,
      month: 6,
      day: 15,
    },
    description:
      "El mismo día que viajaba a París a conocer en persona a Doris, ella se dirigía a Le Mont Saint-Michel en una excursión que había reservado.",
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/paris-eiffel-tower.jpeg",
    imageLabel: "torre eiffel",
    linkText: "Continue reading…",
    content: post2,
    keywords: [
      "ldr",
      "taiwan",
      "long distance relationship",
      "digital",
      "distancia",
      "flechazo",
      "relaciones",
      "tinder",
      "amor",
      "encuentro",
      "first time",
      "love",
      "meetup",
      "paris",
      "primera vez",
    ],
    categories: ["Long distance relationship"],
    meta: {
      title:
        "Nuestro primer encuentro en persona en París | We meet first time in Paris",
      description:
        "Nos conocimos en Tinder y nos encontramos por primera vez en persona en París.",
    },
  },
  {
    slug: "flechazo-digital",
    title: "Flechazo digital",
    date: {
      year: 2019,
      month: 6,
      day: 2,
    },
    description:
      "Cuando imaginamos un flechazo solemos pensar en escenas de película donde una mirada, un gesto o un detalle, despiertan un torrente de emociones y se crean una conexión especial con alguien que pocos segundos antes era un completo desconocido.",
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/paris-eiffel-tower.jpeg",
    imageLabel: "torre eiffel",
    linkText: "Continue reading…",
    content: post1,
    keywords: [
      "ldr",
      "taiwan",
      "long distance relationship",
      "digital",
      "distancia",
      "flechazo",
      "relaciones",
      "tinder",
    ],
    categories: ["Long distance relationship"],
    meta: {
      title: "Flechazo digital | Love at first sight",
      description:
        "Cómo conocí a mi mujer a través de Tinder y cómo empezamos nuestra relación a larga distancia.",
    },
  },
  {
    slug: "the-beauty-of-taiwan-a-collection-of-textures",
    title: "The beauty of Taiwan: A collection of images",
    date: {
      year: 2019,
      month: 2,
      day: 21,
    },
    description: "The Beauty of Taiwan: A Collection of Textures",
    image:
      "https://d30ay7ug033loj.cloudfront.net/images/taipei-skyline-day.jpg",
    imageLabel: "city skyline",
    linkText: "Continue reading…",
    content: post28,
    keywords: ["taipei", "taiwan", "texture", "photography"],
    categories: ["Taiwan"],
    meta: {
      title: "The Beauty of Taiwan: A Collection of Textures",
      description: "The Beauty of Taiwan: A Collection of Textures",
    },
  },
];

export const sidebar = {
  title: "About",
  description:
    "We met on the Internet, we fell in love in Paris, and now we lived between the Netherlands and Taiwan.",
  archives: [
    { title: "March 2020", url: "#" },
    { title: "February 2020", url: "#" },
    { title: "January 2020", url: "#" },
  ],
  social: [
    {
      name: "Instagram",
      icon: InstagramIcon,
      link: "https://www.instagram.com/one.pan.fmly/",
    },
    {
      name: "Youtube",
      icon: YoutubeIcon,
      link: "https://www.youtube.com/@onepanfamily8915",
    },
    {
      name: "Twitter",
      icon: TwitterIcon,
      link: "https://twitter.com/SantiPanCar",
    },
    {
      name: "GitHub",
      icon: GitHubIcon,
      link: "https://github.com/santiago-pan",
    },
  ],
};

export const categoryDescription: Record<
  string,
  { title: TCategory; description: string | null }
> = {
  taiwan: {
    title: "Taiwan",
    description: "Topics about Taiwan and living in Taiwan.",
  },
  "long-distance-relationship": {
    title: "Long distance relationship",
    description: "Our long distance relationship history",
  },
  marriage: {
    title: "Marriage",
    description: "About marriage in Taiwan",
  },
  pregnancy: {
    title: "Pregnancy",
    description: "Pregnancy journey",
  },
  food: {
    title: "Food",
    description: "Food in Taiwan",
  },
  work: {
    title: "Work",
    description: "How is work in Taiwan",
  },
  travel: {
    title: "Travel",
    description: "Travel tips and topics",
  },
  sports: {
    title: "Sports",
    description: "Topics about sports",
  },
};

export default {
  posts,
};
