import React from "react";
import { posts } from "../content/Content";
import { postUrl, slugify } from "../Tools";

const BASE = "https://onepanfamily.com";

export function generateSiteUrl() {
  const base: string[] = ["", "/home", "/blog", "/contact"];

  const postsUrls = posts.map((p) => postUrl(p));

  const tagsSet = new Set(
    posts.flatMap((p) => p.keywords.map((k) => slugify(k)))
  );

  const categoriesSet = new Set(
    posts.flatMap((p) => p.categories.map((c) => slugify(c)))
  );

  const tagUrls = [...tagsSet.values()].map((k) => `/tag/${k}/`);

  const categoryUrls = [...categoriesSet.values()].map(
    (c) => `/category/${c}/`
  );

  // const sitemap = [...base, ...postsUrls, ...tagUrls, ...categoryUrls].map(
  const sitemap = [...base, ...postsUrls].map((u) => `${BASE}${u}`);

  return (
    <div>
      {sitemap.map((s) => (
        <div key={s}>
          <a href={s} target="_blank">
            {s}
          </a>
        </div>
      ))}
    </div>
  );
}
