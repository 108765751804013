# 我在第二孕期的身體改變

_October 11, 2020 by [Doris](/)_

<figure align="center">
<img alt="pollow on bed" src="https://d30ay7ug033loj.cloudfront.net/images/pillow_bed.jpeg" width="100%"/>
</figure>

其實第二孕期（14~28週）算是相對舒適的一個階段，少了剛懷孕時的慌張感，更習慣和寶寶共處，也逐漸恢復孕前的運動習慣，走步道、打壁球、健身房，還有下班走路回家。整體來說，心理更加踏實，那些懷疑自己能否承擔媽媽責任的心情都過了。

最明顯的改變是我的鼻過敏更加嚴重了，別說睡覺打呼了，如果不使用鼻噴劑的話，我白天就會斷氣。所以有跟醫生開鼻過敏的藥吃，每天睡前固定一顆，雖然半夜還是會起床噴一次鼻子（順便尿尿），但至少好睡很多很多。

另外因為nifty檢查結果我有子癲前症高風險（就醫生的說法是胎盤小一點、某條動脈也窄了一些，所以當小朋友要吸取養份的時候，就會讓我血壓升高，尤其小朋友週數愈大，愈會讓我血壓上升），所以也開始預防性地吃阿斯匹靈，從14週開始每天定時一顆。

真是還沒出生就讓媽媽高血壓的孩子。不過我每次到醫院或看到血壓計都會量一下，到目前29週的血壓都很正常（收縮110~120、舒張65~75），希望好好保持。

胸部好像不再長大了，我印象中在第一孕期的時候，胸部跳躍式從70C漲到80E，當時買的內衣現在都還可以穿。胸下圍會冒汗，也是有點糗。在家沒穿內衣的話，有時還會看到胸下圍被汗浸濕的印子，有點好笑也有點情色。

在20週產檢時，醫生問「有沒有感受到胎動了？」「沒有。」「應該要有的，有點像羽毛搔癢滑過肚皮的感覺，晚上9:00後會更明顯，你可以感受看看。」結果當晚就看到了第一次的胎動！從此之後就是動不停。

再來約18週後，某天我的肚皮超級癢，超級超級癢！立刻買了妊娠油和乳液，擦了兩天卻無法緩解我的乾癢，反而還更癢。我以為皮膚病了，結果還擦了薄荷之類的膏（就是家母口中說一罐可抵好幾罐，頭痛擦頭、蚊子叮咬也可擦、燒燙傷也先擦的那種萬用膏），皮膚刺痛倒在床上打滾。後來是耐著性子繼續擦不同的妊娠油（百洛、帕瑪氏、蜂蜜爺爺、Biomama）搭配乳液（理膚寶水、erbabviva）才舒緩了肚皮的乾癢。接著就看著自己肚子急速長大。直到現在我還是每晚都會擦，順便在鏡子前面看自己身體的變形。肚子長大、屁股變肉變寬、大腿根部變粗、上手臂也愈來愈肉了，你明知道自己正懷孕著，這些改變都不是你想要的，但就是忍不住嘆了口氣，提醒自己身材一定回得去。

<div align="center">
<figure style="width:80%">
<img alt="妊娠乳液" src="https://d30ay7ug033loj.cloudfront.net/images/erbaviva_cream.jpeg" width="100%"/>
<figcaption><a href="https://www.1010hope.com/erbaviva-stretch-mark-cream.html">1010 HOPE</a>，個人覺得好用，倒是百洛對我還不夠滋潤</figcaption>
</figure>
</div>
<br>

肚子逐漸大了，睡覺也只能側睡，月亮枕好像是個必需品，所以我買了[Hugsie的防塵蟎涼感月亮枕](https://hugsie.com.tw/product/anti-mitesseriescottoncoolingtouch/)。它真的好大一隻，像恐龍睡在我床上。至於實不實用？我覺得夾著它入睡蠻舒服，而且隨著躺在床上時間變多，它也是一個很好墊身體各部位的枕頭。涼感布也讓夏天稍微舒服一些。愈到孕後期，愈需要月亮枕。體貼太太懷孕辛苦，買顆月亮枕吧。

<div align="center">
<figure style="width:80%">
<img alt="月亮枕" src="https://d30ay7ug033loj.cloudfront.net/images/maternity_pillow.jpeg" width="100%"/>
<figcaption>買了兩條枕套，還有寶寶安撫枕巾，可以把月亮枕變圓，放寶寶在上面</figcaption>
</figure>
</div>
<br>

28週之後，開始感受到明顯的腰痠和尾椎痛，買了托腹帶（不習慣）、為辦公椅增添護腰枕（沒用）、換坐瑜珈球（因為沒靠背只能坐1-2小時），目前還沒找到較舒適的解方，兩隻小腿也漸漸地腫脹，只好靠孕婦按摩了。這部分留到下次再說吧，祝正在看文章的你和小寶貝平安健康。
