import { TPost } from "./Types";

export function slugify(text: string) {
  return text.toLowerCase().replace(/ /g, "-");
}

export const toTitleCase = (text: string) => {
  return text
    .toLowerCase()
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function pad(num: number, places: number) {
  return String(num).padStart(places, "0");
}

export function postUrl(post: TPost) {
  return `/${post.date.year}/${pad(post.date.month, 2)}/${pad(
    post.date.day,
    2
  )}/${post.slug}/`;
}
