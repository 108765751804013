# How to get married in Taiwan

_June 24, 2020 by [Santiago](/)_

<figure align="center">
<img alt="marriage picture" src="https://d30ay7ug033loj.cloudfront.net/images/weding-picture.jpeg" width="100%"/>
</figure>


If you are reading this post you will surely find yourself in the same situation I was in a few years ago, you have fallen in love with a woman or a man from Taiwan, you have come to visit this wonderful country and now you are clear that you want to get married and build a future together. My sincere congratulations! In this post I am try to explain what is the process to get married in Taiwan and what documents would you need.

## Documents you need

Getting married in Taiwan with a foreigner is easier than in other countries in terms of necessary documents, although on the other hand those same documents must be legalised and translated, which makes the process somewhat more cumbersome and slow, especially if you do not live in your country of origin.

My nationality is Spanish, but I am sure that the process is similar for many other countries. These are the documents that you should obtain:

- Certificate of marital status
- Birth certificate

It’s that simple. The next step is to prepare these documents for acceptance by the civil registry in Taiwan. For this, both certificates must be:

- [Legalised](http://www.exteriores.gob.es/Portal/es/ServiciosAlCiudadano/SiEstasEnElExtranjero/Paginas/Legalizaciones.aspx) by the corresponding Spanish administrations
- [Translated](https://www.exteriores.gob.es/es/Paginas/Page-not-found.aspx?requestUrl=https://www.exteriores.gob.es/Portal/es/ServiciosAlCiudadano/Documents/Listado%20actualizado.pdf) by a sworn translator certified by the MAEC
- Legalised by the [Taipei Economic and Cultural Office in Spain](https://www.roc-taiwan.org/es_es/index.html)

Each certificate has a different legalisation chain.

For example, the birth certificate must be legalised first by the Superior Court of Justice of your autonomous community, followed by the Ministry of Justice in Madrid and finally by the MAEC.

My certificate of civil status was issued at the Spanish consulate in Amsterdam, which allows it to be legalised directly at the MAEC, in recognition of the signature of a Spanish diplomat.

## Agency

Living outside of Spain I have had no choice but to hire a manager to carry out the whole process and, honestly, from my point of view it is the best solution. Many of the sworn translators working for the ministry also offer the full legalisation service, so you only have to worry about getting the certificates and sending them to them and they return exactly what you need for civil registration in Taiwan. In my case the price for the two documents has been 400 euros.

## Chinese name and witnesses

To register your marriage you will need two additional things:

- Choose a Chinese name for you
- Find two witnesses

Witnesses do not have to be present, they only need to sign a document with their names indicating that they recognise the couple and the marriage.

The funniest part is thinking about what your Chinese name is going to be, since you need to have one for registration. Remember that you should practice writing in traditional Chinese characters, because you must write it on the form during registration. Your partner can help you fill out the form, but I think it’s more interesting if you can do it yourself.

## Marriage registration in Taiwan

The day has come, you and your partner have all the documentation, you have chosen the date and you already have the rings ready, so the next step is to register the marriage at the [Household Registration Office](https://englishhr.gov.taipei/). Here your Taiwanese partner will have it easier, since they will probably know how it works and where to go. It is very similar to a public office in Spain, where at the entrance you take a number, wait for your turn to arrive, and go to the window that touches you. There you present all the documentation, do not forget your passport, and after a few minutes, you are already married!

It is important that you take advantage of that same day to request the original marriage certificate in English, and a copy of your civil status certificate, since you will need the next step to register your marriage in the Spanish civil registry, which is essential for subsequently apply for [Resident Visas for Foreign Spouses](https://www.boca.gov.tw/cp-166-280-9f808-2.html).

But I will talk about this in another post.

Greetings and good luck!