import { Paper, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import { META_DESCRIPTION, META_TITLE, sections } from "./../content/Content";
import Footer from "./Footer";
import Header from "./Header";

const theme = createTheme();

export default function Home() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{META_TITLE}</title>
        <meta name="description" content={META_DESCRIPTION} />
      </Helmet>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="OnePanFamily" sections={sections} />
        <main>
          <Typography variant="h4" color="inherit" paragraph>
            A beautiful family...
          </Typography>
          <Paper
            sx={{
              position: "relative",
              backgroundColor: "grey.800",
              color: "#fff",
              mb: 4,
              height: "auto",
              width: "100%",
              minHeight: "420px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundImage:
                "url('https://d30ay7ug033loj.cloudfront.net/images/taipe-skyline-night.jpg')",
              maskImage: "url('/images/mask-2.png')",
              maskSize: "cover",
              maskRepeat: "no-repeat",
              maskPosition: "center",
            }}
          ></Paper>
          <Typography
            variant="h4"
            color="inherit"
            sx={{
              marginTop: "32px",
            }}
            paragraph
          >
            …that started as long distant relationship.
          </Typography>
          <Typography
            variant="body1"
            color="inherit"
            sx={{
              marginTop: "32px",
            }}
            paragraph
          >
            We met on the Internet while both were living in their respective
            countries, the Netherlands and Taiwan. We hit it off immediately and
            decided to meet in person in Paris, the city of love. As we walked
            along the Seine, admiring the stunning architecture and enjoying the
            culinary delights of the city, we knew we had found something
            special in each other. After a week of romantic adventures in Paris,
            we decided to make the long-distance relationship work. We spent the
            next year traveling back and forth between the Netherlands and
            Taiwan, getting to know each other's cultures and building a strong
            foundation for our relationship. Eventually, we decided to make the
            leap and move in together, choosing to split our time between the
            Netherlands and Taiwan. We loved the contrast between the bustling
            cities and the beautiful natural scenery of both countries. Now,
            after several years of living this unique and exciting lifestyle, we
            are happier than ever. We continue to travel and explore new places,
            always finding joy and adventure in our life together.
          </Typography>
          <Typography variant="h4" color="inherit" paragraph>
            Taiwan
          </Typography>
          <Typography variant="body1" color="inherit" paragraph>
            Taiwan is a beautiful island nation located off the coast of
            southeastern China. It is known for its stunning natural scenery,
            vibrant culture, and friendly people. The country has a subtropical
            climate, so it is warm and pleasant throughout the year. Taiwan is a
            great destination for travelers who want to experience a unique
            blend of traditional Chinese and modern East Asian culture.
          </Typography>
          <Typography variant="h4" color="inherit" paragraph>
            The Netherlands
          </Typography>
          <Typography variant="body1" color="inherit" paragraph>
            The Netherlands is a small, flat country located in Western Europe.
            It is known for its beautiful tulip fields, picturesque canals, and
            historic windmills. The country has a maritime climate, with cool
            summers and mild winters. The Netherlands is a great place to live
            and visit, with a rich cultural heritage and a diverse population.
            The people are known for their friendliness and hospitality, and the
            country has a strong economy and a high standard of living. The
            capital city of Amsterdam is a popular destination for tourists,
            with its historic buildings, museums, and vibrant nightlife.
            Overall, the Netherlands is an exciting and welcoming place to live
            and visit.
          </Typography>
        </main>
      </Container>
      <Footer title="" description="" />
    </ThemeProvider>
  );
}
