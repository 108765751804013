# 懷孕了，何時該跟主管報備？

_September 24, 2020 by [Doris](/)_

<figure align="center">
<img alt="ultrasound pic" src="https://d30ay7ug033loj.cloudfront.net/images/work-family-balance.jpeg" width="100%"/>
</figure>

這是一個大哉問，因為跟產業、職務內容、公司文化都有很大的關係。同事說他的朋友在會計事務所工作，懷孕到六個月後遮不住了，才讓部門的人知道。我媽告誡我「千萬不要太早跟主管說，到時候你的工作受影響…」。看到批踢的媽寶版討論產檢假/育嬰假被刁難，或孕產期相關的權益被剝奪等，覺得我媽會擔心也是正常。

我很慶幸，廣告媒體產業的文化蠻開明，反倒是自己扭扭捏捏不好意思跟同事們說，只能透露給比較要好的同事知道，畢竟當你食慾大開時，好同事們都會發現的，「你怎麼又再吃東西…」(:3 ⌒ﾞ)

我應該是懷孕四個月的時候才當面跟主管說，因為我連有男友或結婚都不曾公開聊過 ಠ_ಠ 其實都是我自己的偶包，結婚、懷孕或同性在我們辦公室、我們產業真的沒什麼！

回想當時也是蠻好笑的，在我**鼓起勇氣**跟直屬主管報備有孕已婚之後，主管面無表情地說「我知道了」（我想他還在震驚中），還問我打算怎麼告知所有同事們？「蛤？可以…不要說嗎？」隔天他就幫我發了封信件給部門所有人（當然有我同意），同時告知結婚和懷孕的事情，還命名我兒叫「小山地」。

而且自從說出口之後，**職場生活簡直海闊天空**。進會議室，一定有座位坐；訓練教室，一定是坐在有椅背的椅子上；出門開會帶筆電，一定有同事（或主管）幫忙提；想要蹲下拿個東西，年輕妹妹會衝過來「你要拿什麼我幫你，你不要這樣啊～～～」；跟客戶開會，她會輕聲細語叫我要保重身體。大家這般禮讓孕婦，簡直登天啦我 (≖ᴗ≖๑)

事後想想，覺得「滿四個月再公開」的時間點其實蠻好的，因為還有半年的時間讓部門主管安排產假時期的工作規劃、交接、還有代理人，我自己也能慢慢訓練代理人。同時我也有大概跟主管跟同事說自己的產假計畫（產假+婚假+特休，大概三個月），畢竟這事只能說個大概，胎兒何時要出來也要看他興致。

主管「你要不要連育嬰假一起請？」
我「不要。」（十分果斷）
主管「為何不要？」
我「不想時刻跟寶寶黏在一起。」

總之我想表達的是，因為公司大，所以人事制度十分合法、也相對完善；加上產業別，所以主管同事都非常有人情味，覺得溫馨。像我們家HR每月固定安排職醫護時間，有需求的人可以自行申請。懷孕初期因為不懂的事情太多了，所以我就申請了一次面談，在這30分鐘內可以一對一地諮詢所有不懂的事情，對新手無知孕婦是很大的幫助。護理師在第一次訪談後，提議之後每三個月都再來聊聊。所以上週是第二是次見到她，專業地幫我釐清一些焦慮，與其上網爬文搞得自己半信半疑，不如好好問專業護理師。

因為這個題目，剛好看到一篇文章「[被壓榨的一代：說不出口的「隱匿型懷孕」與職場壓迫](https://www.twreporter.org/a/bookreview-squeezed-why-our-families-cant-afford-america)」，原來懷孕歧視不分國界，我相信隨著女性愈來愈不願意生育，相關的制度和社會風氣肯定會愈來愈正向的。

山地也跟我分享荷蘭的產假制度，他計畫跟公司談彈性使用配偶產假的方式，他打算將5週不支薪的產假拆成10週，也就是他每天上班4小時，這樣至少還有半薪也能一起照顧小孩。公司的回應是非常正面，主管甚至感動落淚（因為山地有了孩兒），原來我們倆都遇到了職場貴人。