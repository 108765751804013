# Cómo canjear el permiso de conducir en Taiwán

_Diciembre 15, 2022 by [Santiago](/)_

<figure align="center">
<img alt="coche con casa y montañas de fondo" src="https://d30ay7ug033loj.cloudfront.net/images/car_mountains_view.jpg" width="100%"/>
</figure>

Si te estás planteando vivir en Taiwán por un periodo largo de tiempo y quieres poder usar un coche o una motocicleta es probable que puedas canjear el permiso de conducir de tu país de origen por el permiso local sin tener que pasar un examen de conducir.

La posibilidad de realizar el canje por un permiso de conducir taiwanés se basa en el [Principio de Igualdad y Reciprocidad](https://www.thb.gov.tw/page?node=408d4b33-d248-46ed-8c2b-9066595af0f4) establecido entre Taiwán y otros países. En términos generales, se igualan cuales son los derechos que un ciudadano taiwánes con permiso de conducir taiwanés tiene en otro país y viceversa.

Por tanto, lo primero que debes hacer es revisar cual es el acuerdo establecido entre el país donde tu permiso ha sido expedido y Taiwán. En particular, lo que debes comprobar es si el permiso de conducir taiwanés se puede convertir al permiso local. Por ejemplo, en mi caso, mi permiso está expedido en Países Bajos, y en la siguiente imagen se puede ver que "Convertible to local driver's license?" dice "Yes".

<figure align="center">
<img alt="ejemplo de acuerdo entre paises" src="https://d30ay7ug033loj.cloudfront.net/images/license_convertible_agrement.jpeg" width="80%"/>
</figure>

En [este](https://www.thb.gov.tw/sites/ch/modules/download/download_list?node=831dcd37-7259-47c3-9211-ffbf8dee35bc&c=89275811-dcff-4d14-877e-981db78a5b18) enlace puedes consultar cuál es el acuerdo con tu país.

Si estás de suerte, esto es lo siguiente que necesitas para solicitar el canje:
- Copia de tu ARC y presentar original
- Realizar la autenticación de tu permiso de conducir. Normalmente este trámite puedes solicitarlo en la oficina representativa de tu país en Taiwán (embajadas).
- Si tu permiso o la legalización no disponen de traducción al inglés deberás presentar también una traducción legalizada.
- Pasar un examen físico en Taiwán. Tendrás que acudir a un hospital y presentar el formulario número 21, que podrás obtener en las oficinas de vehículos a motor de tu ciudad. En el formulario deberás rellenar tus datos y pegar una foto.
- Presentar los documentos necesarios en la oficina de vehículos a motor de la ciudad donde residas, por ejemplo en [Taipei](https://goo.gl/maps/kVGPQ1vYVpukh42X6)
- Un par de fotos de tamaño carnet grande. Si no las tienes, en la misma oficina tienen una máquina para sacarte las fotos.

[Aquí](https://tpcmv.thb.gov.tw/english/ServicesEng/LicenseEng/ManagementEng/ManagementEng02.htm) puedes encontrar más información sobre los pasos que debes realizar.

Espero que esta información te pueda servir de ayuda y te animes a conducir en Taiwán. Te permitirá llegar a lugares increíbles de este país que de otra manera sería mucho más difícil.
