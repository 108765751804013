# Taxes in Taiwan

_December 23, 2021 by [Santiago](/)_

<figure align="center">
<img alt="two euro coin" src="https://d30ay7ug033loj.cloudfront.net/images/2-euro-coin.jpeg" width="100%"/>
</figure>

When you live and work in different countries, one of the things you must take into account is how to manage the payment of taxes. In this post I want to share my particular process in case it can help someone who is in the same situation.

I live in Taiwan with my wife, who is a Taiwanese citizen, and since I have already resided in this country for more than 90 days, I have to declare my income and pay taxes in this country.

My income comes from the work I do for a company in the Netherlands, which pays me in euros into a bank account domiciled in the Netherlands. You may read on some sites that in situations like this you could avoid declaring any income and not paying taxes, since it seems difficult for the Taiwanese administration to have access to such data. This is a personal decision and each one decides what actions to take and assume the consequences thereof. In my case I have preferred to do things as I think are correct and have a clear conscience.

## When do you have to pay taxes?

The tax return for a given year is made in the month of May of the following year. The payment or return must also be made or occurs in that same month.

## What documents do you need?

You must present two types of documents, those related to your identification and those related to your income.

In my case, ARC and passport and my tax return from the country where I receive my income, the Netherlands. Why do I have to do the income tax return also in the Netherlands? Since my salary is paid in the Netherlands and I have certain properties in this country, I am interested in filing the income tax return to recover some of the withholdings made on my salary. In this income statement, among other things, they show what my real income has been throughout the year, which is the data that will be used to calculate how much it is necessary to pay in taxes.

## Where to make the declaration?

With the papers ready, my wife and I headed to the nearest office. To find yours you can search for ["National Taxation Bureau"](https://www.ntbt.gov.tw/English) in Google. Once there, we request to do the joint declaration, so they add my income data to the tax information they automatically obtain from my wife. With all the data entered, the system selects the most advantageous formula, that is, the one that means less tax payment. If you agree, they print a document with the final result of the declaration, how much you have to pay or how much they will refund you, depending on each situation.

## How is it paid?

All forms of payment are detailed in the final document. There are many options, from paying in cash, bank transfer, by card or even at 7-eleven.

Paying taxes is intimidating, especially if you have to do it in a foreign country. Each case is different and will have different ways of resolution.

I hope mine can help you.

If you want more information, I recommend you consult this [blog](https://www.foreignersintaiwan.com/blog-370963385326684/income-tax-filing-for-expatriates-in-taiwan) with a series of FAQs that can help you in your case.