# How to exchange your driver's license in Taiwan

_December 15, 2022 by [Santiago](/)_

<figure align="center">
<img alt="car with mountains in the background" src="https://d30ay7ug033loj.cloudfront.net/images/car_mountains_view.jpg" width="100%"/>
</figure>

If you are considering living in Taiwan for a long period of time and want to be able to use a car or motorcycle, you may be able to exchange your home country driver's license for a local driver's license without having to pass a driving test.

The possibility of exchanging for a Taiwanese driver's license is based on the [Principle of Equality and Reciprocity](https://www.thb.gov.tw/page?node=408d4b33-d248-46ed-8c2b-9066595af0f4) established between Taiwan and other countries. In general terms, the rights that a Taiwanese citizen with a Taiwanese driver's license have in another country are equalized and vice versa.

Therefore, the first thing you should do is check the agreement established between the country where your permit has been issued and Taiwan. In particular, what you need to check is whether the Taiwanese driver's license can be converted to the local license. For example, in my case, my license is issued in the Netherlands, and in the following image you can see that "Convertible to local driver's license?" says "Yes".

<figure align="center">
<img alt="example of agreement between countries" src="https://d30ay7ug033loj.cloudfront.net/images/license_convertible_agrement.jpeg" width="80%"/>
</figure>

In [this](https://www.thb.gov.tw/sites/ch/modules/download/download_list?node=831dcd37-7259-47c3-9211-ffbf8dee35bc&c=89275811-dcff-4d14-877e-981db78a5b18) link You can check what the agreement is with your country.

If you are lucky, this is the next thing you need to request the exchange:
- Copy of your ARC and submit original
- Authenticate your driving license. Normally you can request this procedure at the representative office of your country in Taiwan (embassies).
- If your permit or legalization does not have an English translation, you must also submit a legalized translation.
- Pass a physical examination in Taiwan. You will have to go to a hospital and present form number 21, which you can obtain at the motor vehicle offices in your city. In the form you must fill in your information and paste a photo.
- Present the necessary documents at the motor vehicle office of the city where you reside, for example in [Taipei](https://goo.gl/maps/kVGPQ1vYVpukh42X6)
- A couple of large passport size photos. If you don't have them, in the same office they have a machine to take your photos.

[Here](https://tpcmv.thb.gov.tw/english/ServicesEng/LicenseEng/ManagementEng/ManagementEng02.htm) you can find more information about the steps you need to take.

I hope this information can help you and encourage you to drive in Taiwan. It will allow you to reach amazing places in this country that would otherwise be much more difficult.