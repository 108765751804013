# 西班牙配偶在台灣登記結婚的瑣事

_August 1, 2020 by [Doris](/)_

<figure align="center">
<img alt="ultrasound pic" src="https://d30ay7ug033loj.cloudfront.net/images/cropped.jpeg" width="100%"/>
</figure>

這篇是教學文，因為山地已經寫了英文、西文各一篇，但我覺得有必要用中文交代一下申請文件的過程，因為Google到的文章都已經蠻久了，不如就來幫助跟我一樣因為看不懂申請流程差點放棄異國婚姻的台灣人吧 **( ´Д`)y━･~~**

##### 為了在台灣登記結婚，西班牙配偶方需要準備什麼？

1. 出生證明（Certificado literal de nacimiento）一份
2. 婚姻狀態證明（Certificado de estado civil）一份

首先是**出生證明**，必須向他的出生地申請，獲得當地認證印章後，還會送到地區（region）獲得印章，最後送到馬德里再累積一枚印章。重重認證就是一種region同意city的認證，capital再同意region的認證。然後，需要找宣誓翻譯員（sworn translator）翻譯成繁體中文，用印；最後再送到駐西班牙台北經濟文化辦事處用印。以上，出生證明獲得5枚印章。

其次是**婚姻狀態證明**，因為山地在荷蘭工作，所以他向當地的西班牙大使館申請這份文件，大使館用印後又送到馬德里累積印章，然後宣示翻譯員的翻譯與用印，最後一樣送到西班牙台北辦事處用印。以上，婚姻狀態證明獲得4枚印章。

##### 結婚登記那天必須準備什麼？

1. 外籍配偶的護照正本
2. 台灣人的身份證正本
3. 有2位證人簽名的結婚書約一份。網路上有蠻多版本可以下載，我使用的是有中英文的版本，讓山地比較安心知道他的簽名用在什麼地方。這裡有[高雄市小港戶政事務所的連結](https://orgws.kcg.gov.tw/001/KcgOrgUploadFiles/187/relfile/12861/25565/7f3b0a3e-d24a-4657-ad13-88dbfe0c17bf.pdf)。登記結婚當天證人不用到現場喔。
4. 外籍配偶取用中文姓名聲明書一份。這裡有台北[松山區戶政事務所的連結](https://www-ws.gov.taipei/Download.ashx?u=LzAwMS9VcGxvYWQvNDE3L3JlbGZpbGUvNDI5NzIvNzU5MDYzOC84MjhiZmQ4NC05OGZjLTQyMWItOWMwZC0xNDYxMDg1ZDk1N2YucGRm&n=5aSW57GN6YWN5YG25Y%2bW55So5Lit5paH5aeT5ZCN6IGy5piO5pu4LnBkZg%3d%3d&icon=..pdf)。
5. 新台幣50元，更換身分證的工本費。
6. 你的身份證照片，如果你想換照片的話。

我對於台灣戶政事務提供的各種文件版本感到非常的困擾，每個事務所版本都有些不一樣，也沒有多語版本 ٩(ŏ﹏ŏ、)۶ 甚至因為找不到某些文件的網站，反而對山地不耐煩。還好他是這世上最有耐性的男子。

準備完所有文件後，竟然被我找到[內政部戶政各項業務申請書下載](https://www.ris.gov.tw/app/portal/163)的網頁。這，就獻給各位了，可以少走很多忐忑不安的冤枉路。

我們討論了一下何時是婚姻生效日，山地覺得2020年4月4日很好記，但是當天是星期六，於是我們就在4月4日的三天前去登記，也就是4月1日愚人節。這也是蠻不容易忘記的一天，哈。

##### 登記結婚後，需要順便申請？

1. 中文結婚證書
2. 英文結婚證書
3. 英文戶籍謄本，不可省略記事。

中文結婚證書一份是為了向公司申請結婚禮金，哈。英文結婚證書和英文戶籍謄本是準備在西班牙登記結婚用的。但我的中英文結婚證書個申請了三份（$100/份），沒用到的純粹紀念。

當你搞清楚流程後，可以把山地寫的文章傳給你的西班牙伴侶，祝福你們一切順利。

- [Cómo casarse en Taiwán](https://onepanfamily.com/2020/06/22/como-casarse-en-taiwan/)
- [How to get married in Taiwan](https://onepanfamily.com/2020/06/24/how-to-get-married-in-taiwan/)

至於台灣人要準備哪些文件才能向西班牙登記婚姻？這我們留在下一篇文章再說吧 **_(:3 ⌒”)_**

<div align="center">
<figure style="width:60%;">
<img alt="marriage" src="https://d30ay7ug033loj.cloudfront.net/images/IMG_9809-1-770x1024.jpeg" width="100%"/>
<figcaption>2020年4月1日愚人節，我們登記結婚了。</figcaption>
</figure>
</div>
<br>