# Cómo casarse en Taiwán

_July 15, 2020 by [Santiago](/)_

<figure align="center">
<img alt="foto de matrimonio" src="https://d30ay7ug033loj.cloudfront.net/images/weding-picture.jpeg" width="100%"/>
</figure>

Si estas leyendo este post seguro que te encuentras en la misma situación en la que yo estaba hace unos años, te has enamorado de una mujer o un hombre de Taiwán, has venido a visitar este maravilloso país y ahora tienes claro que os queréis casar y construir un futuro juntos. ¡Mi más sincera enhorabuena! En este post voy a tratar de explicar cuál es el proceso que debes seguir y qué documentos necesitas para casarte en Taiwán.

## Documentos que necesitas

Casarse en Taiwán con un extranjero es algo más sencillo que en otros países en cuanto a documentos necesarios, aunque por otro lado esos mismos documentos deben ser legalizados y traducidos, lo que hace el proceso algo más engorroso y lento, sobretodo si no vives en tu país de origen.

Mi nacionalidad es española, pero estoy seguro que el proceso es similar para muchos otros países. Estos son los documentos que debes obtener:

- Certificado de estado civil
- Certificado literal de nacimiento

Así de simple. El siguiente paso es preparar esos documentos para que sean aceptados por el registro civil en Taiwan. Para ello ambos certificados deben estar:

- [Legalizados](http://www.exteriores.gob.es/Portal/es/ServiciosAlCiudadano/SiEstasEnElExtranjero/Paginas/Legalizaciones.aspx) por las administraciones españolas correspondientes
- [Traducidos](https://www.exteriores.gob.es/es/Paginas/Page-not-found.aspx?requestUrl=https://www.exteriores.gob.es/Portal/es/ServiciosAlCiudadano/Documents/Listado%20actualizado.pdf) por un traductor jurado certificado por el MAEC
- Legalizados por la [oficina económica y cultural de Taipei en España](https://www.roc-taiwan.org/es_es/index.html))

Por ejemplo, el certificado de nacimiento debe ser legalizado primero por el Tribunal Superior de Justicia de la tu comunidad autónoma, seguido del Ministerio de Justicia en Madrid y finalmente por el MAEC (sección de legalizaciones).

Mi certificado de estado civil fue expedido en el consulado de España en Ámsterdam, lo que permite legalizarlo directamente en el MAEC, como reconocimiento de firma de un diplomático español.

## Gestor

Al vivir fuera de España no he tenido otra opción que contratar un gestor para realizar todo el proceso y, sinceramente, desde mi punto de vista es la mejor solución. Muchos de los traductores jurados que trabajan para el ministerio ofrecen también el servicio completo de legalización, de manera que sólo te tienes que preocupar de conseguir los certificados y enviárselos y ellos te devuelven exactamente lo que necesitas para el registro civil en Taiwán. En mi caso el precio por los dos documentos ha sido de 400 euros.

## Nombre chino y testigos

Para registrar tu matrimonio necesitarás dos cosas adicionales:

- Escoger un nombre chino para ti
- Encontrar dos testigos

Los testigos no tienen que ser presenciales, sólo es necesario que firmen un documento con sus nombres donde indiquen que reconocen la pareja y el matrimonio.

La parte más divertida es pensar en cuál va a ser tu nombre chino, ya que es necesario disponer de uno para el registro. Recuerda que debes practicar su escritura en caracteres chinos tradicionales, porque deberás escribirlo en el formulario durante el registro. Tu pareja te puede ayudar en cubrir el formulario, pero creo que es más interesante su puedes hacerlo tu mismo.

## Registro matrimonio en Taiwán

Ha llegado el día, tu pareja y tú disponéis toda la documentación, habéis escogido la fecha y ya tenéis los anillos listos, así que el siguiente paso es registrar el matrimonio en el [Household Registration Office](https://englishhr.gov.taipei/). Aquí tu pareja taiwanesa lo tendrá más fácil, ya que probablemente conocerá cómo funciona y donde ir. Es muy similar a una oficina pública en España, donde a la entrada coges un número, esperas a que llegue tu turno, y te diriges a la ventanilla que te toque. Allí presentas toda la documentación, no te olvides de tu pasaporte, y después de unos minutos, ¡ya estáis casados!

Es importante que aproveches ese mismo día para solicitar el certificado de matrimonio original y en inglés, y una copia de tu certificado de estado civil ya que te hará falta para el siguiente paso, registrar tu matrimonio en el registro civil Español, que es imprescindible para posteriormente solicitar el [Resident Visas for Foreign Spouses](https://www.boca.gov.tw/cp-166-280-9f808-2.html).

Pero de esto os hablaré en otro post.

Un saludo y ¡mucha suerte!