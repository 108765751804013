# Yo en París y tú en Le Mont Saint-Michel

_June 15, 2019 by [Santiago](/)_

<figure align="center">
<img alt="torre eiffel" src="https://d30ay7ug033loj.cloudfront.net/images/paris-eiffel-tower.jpeg" width="100%"/>
</figure>

El mismo día que viajaba a París a [conocer en persona a Doris](https://onepanfamily.com/2019/06/02/flechazo-digital/), ella se dirigía a Le Mont Saint-Michel en una excursión que había reservado. Me preguntó si quería que la cancelara para que pudiéramos pasar más tiempo juntos, pero le dije que no, que me parecía una pena perder la oportunidad de ver un sitio tan bonito que probablemente no tendría ocasión de ver en otro momento. Además ese mismo día era su cumpleaños, y se había regalado a sí misma visitar ese precioso lugar.

No dejaba de parecerme gracioso que mientras yo me acercaba a ella, ella se alejaba de mi 🙂

El día anterior al viaje, atendía a una conferencia con un compañero de trabajo. Compartí con él todo lo que me estaba sucediendo y el plan de ir a París. Era la primera vez que hablaba con alguien de ello y eso me ayudó a hacerlo más real.

A lo largo del día Doris y yo hablamos de cómo sería el encuentro, de cuánta vergüenza sentiríamos y de que no seríamos capaces de decirnos ni una sola palabra. El día pasó volando, ella completando su ruta turística y yo de presentación en presentación.

A la mañana siguiente, antes de dirigirme a la estación, compré snacks, algunas cervezas y una botella de un rioja que me gusta mucho. Seguíamos planeando el día, con aspectos más logísticos, sobre donde cenar, ya que ella llegaría tarde y con hambre. Otro tema que rondaba mi cabeza, era si invitarla a pasar la noche en mi hotel y que pudiese traer sus maletas, para ahorrar tiempo al día siguiente con el checkout de su hotel, así que simplemente se lo dije, dejando claro que eso no significaba que tuviese que suceder nada esa noche. A ella le pareció bien y considerado por mi parte.

El viaje a París fue sencillo, algo más de tres horas. Nuestras chats eran una mezcla entre describir nuestros respectivos viajes y expresar nuestros sentimientos y emociones por lo que sucedería dentro de unas horas.

Cuando llegué caminaba por las calles con una sonrisa enorme. No era la primera vez que visitaba la ciudad pero desde luego sería la más especial de todas ellas. Aunque suene a tópico, París sería la ciudad del amor.

El hotel era bonito, la habitación pequeña pero acogedora, con un baño diminuto que no daba mucho espacio para la intimidad, pero tenía la sensación de que eso no sería un problema.

Dejé las cosas, me di una ducha y salí a recorrer la ciudad en las horas que me quedaban antes de verla. No pensé en ningún plan en particular, me dirigí a ver los sitios turísticos tradicionales, picando algo de comida de distintos puestos callejeros y sacando fotos para compartir con ella.

<figure align="center">
<img alt="torre eiffel" src="https://d30ay7ug033loj.cloudfront.net/images/eiffel-tower.jpeg" width="60%"/>
</figure>


Ella ya estaba en su viaje de vuelta, ahora sí que nuestras distancia se acortaba. Me preocupaba que estuviera muy cansada y sobre todo que no había cenado nada, así que decidí comprar algo de pasta preparada que pudiese calentar fácil en el microondas que había en la cocina del hotel. Lo gracioso es que al final no probamos el vino y la pasta fue mi cena del día siguiente 🙂

– *[20:51] Doris: I can see Eiffel Tower*

– *[20:51] Santiago: So we are very close*

Me fui al hotel a darme otra ducha, quería sentirme fresco y oler bien para ella. Le dije que se tomase el tiempo que necesitara, yo estaba a pocos minutos caminando y sabía que ella tenía que preparar la maleta, ducharse y hacer el checkout del hotel.

<figure align="center">
<img alt="google maps route" src="https://d30ay7ug033loj.cloudfront.net/images/google-maps-route.jpeg" width="60%"/>
</figure>

Nueve minutos caminando nos separaban ahora. Poco después de las diez y media de la noche, ella me dijo que estaba casi lista, así que salí a esperarla. Un montón de emociones se agolpaban al salir de la habitación. Su hotel hacía esquina, había mucha gente entrando y saliendo, mis pulsaciones aumentaban cada vez que se habría la puerta.

Al final, una de esas personas era ella.

Nos reconocimos al instante, llevaba dos maletas, una grande y otra más pequeña, le ayudé a bajar las dos escaleras que daban a la calle, nos miramos un segundo y nos abrazamos, dejando que un torrente de sentimientos inundase nuestros cuerpos. Caminamos hacia nuestro hotel, yo llevaba su maleta y apenas éramos capaces de mantener una conversación sin interrumpirnos con la risa o la vergüenza. La parte más práctica del viaje, cruzando semáforos y encontrando el trayecto más sencillo para las maletas, nos mantenía entretenidos durante los minutos del trayecto.

Llegamos al hotel y entramos en el ascensor, nos miramos y abrazamos otra vez. Llegamos a la habitación, que de repente ya no era una habitación solitaria y pequeña, ahora tenía vida y emoción. No había mucho que ver en ella. Nos pusimos cómodos y ella contactó con amigos para confirmar que estaba bien y que yo no tenía intención de quedarme con sus riñones y venderlos (los dos bromeamos con eso muchas veces antes de vernos).

Nos sentamos en el borde de la cama, picamos algunos snacks y bebimos las cervezas que había traído. El alcohol ayudó a relajarnos, compartimos emociones y sentimientos, pudimos empezar a hablar y compartir cosas. Ella me preguntó si podía poner algo de música, y yo respondí que me encantaría.

Amy Winehouse comenzó a sonar.

Al día siguiente, en nuestro rostro se veía el cansancio de haber dormido pocas horas pero con ojos llenos de felicidad y de amor.