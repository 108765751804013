# Flechazo digital

_June 2, 2019 by [Santiago](/)_

<figure align="center">
<img alt="torre eiffel" src="https://d30ay7ug033loj.cloudfront.net/images/paris-eiffel-tower.jpeg" width="100%"/>
</figure>

Cuando imaginamos un flechazo solemos pensar en escenas de película donde una mirada, un gesto o un detalle, despiertan un torrente de emociones y se crean una conexión especial con alguien que pocos segundos antes era un completo desconocido.

Mi flechazo con Doris se produjo al ver una foto suya en Tinder.

Era una foto en blanco y negro, un primer plano donde sentada en una mesa con la cabeza posada sobre su mano derecha, miraba ligeramente hacia arriba, como el que sueña despierto. Su carita era redonda, con un pequeño flequillo que cubría su frente y una encantadora sonrisa que resaltaba unas mejillas para comérselas.

Por suerte a ella le hicieron gracia mis orejas de soplillo y mi cara de buen chico, y tuvimos un match. En el mundo Tinder este era un momento crítico, el de la primera frase, la que decide en un 99% de los casos si va a haber una respuesta. Revisé sus fotos y una de ellas me sirvió de inspiración.

– *Hi Doris, how are you doing? Where is the picture of the leafy forest?*

<figure align="center">
<img alt="torre eiffel" src="https://d30ay7ug033loj.cloudfront.net/images/doris_pic.png" width="80%"/>
</figure>

– *Hello Santiago* 🙂

– *That picture was taken in Taiwan, my country.*

– *I really like that place. If you come to Taiwan, I can take you to that county.* 🙂

Mi corazón se aceleró cuando vi su respuesta en el chat. Por un lado estaba emocionado al recibir su chat, pero por otro me surgió la duda inmediata de si vivía en Holanda. Así que se lo pregunté, y desafortunadamente, no:

– *No, I wish I could live here. I just visited Amsterdam for 5 days and tmr is the last day* 🙁

– *Then I’ll spend my vacation in Paris until 11 November*

La vida está llena de momentos importantes, pero son algunos pequeños instantes los que marcan el rumbo de la misma, lo que nos llevan a un lugar insospechado o a situaciones nunca antes imaginadas. En este caso, lo que marcó el futuro de nuestras vidas, fue no darle importancia al hecho de la distancia, ignorando ese dato, seguimos nuestra conversación, pensando que en lugar de 9000Km lo que nos separaba por ahora eran algunos minutos en tren.

Hablando de nuestros gustos, aficiones, viajes, lugares favoritos, comida o trabajo, sobre Taiwán, Holanda o España. Ella me contaba cómo iban sus vacaciones y yo le hablaba de mi rutina en el trabajo o en mi tiempo de ocio. El día que Doris tomó el tren a París, decidimos seguir hablando por WhatsApp.

Pasamos de unos cuantos chats diarios a interminables conversaciones, fotos, anécdotas y comentarios graciosos. Los sentimientos comenzaron a expresarse con palabras infiltradas, con emojis, o alguna frase picaresca. Enseguida llegamos a las preguntas personales, a hablar de nuestras relaciones pasadas, de por qué estábamos singles y qué era lo que buscábamos en una relación. Cada respuesta me acercaba más a ella, cada comentario me dejaba una pequeña marca, y cada día me iba a dormir con una sonrisa enorme y una sensación de felicidad.

Un miércoles por la mañana, a cuatro días de que ella volviese a Taiwán, le dije que había tenido un sueño en el que cogía un tren y la iba a visitar a París, ella me respondió que eso sería maravilloso pero que sabía que era difícil:

– *[18:51] Santiago: It may sound a bit crazy, and I do not want to mess up your plans, tomorrow I have a conference but I could take a train on Friday, and be there for when you are back from Sant Michel*

– *[20:04] Doris: Si.*

He añadido la hora porque para mí, en esos minutos, el tiempo se había detenido, mi corazón me salía por la boca y no podía dejar de comprobar el móvil. Al final, dijo “Si”.

Reservé un tren y un hotel cerca del suyo, para el fin de semana. Un torrente de emociones en mi cabeza, y un deseo intenso de verla aunque fuese sólo por unas horas.

El viernes por la mañana cogí un tren a París.
