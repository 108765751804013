import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Blog from "./components/Blog";
import BlogPost from "./components/BlogPost";
import Contact from "./components/Contact";
import Home from "./components/Home";
import { generateSiteUrl } from "./tools/SiteGen";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/category/:categoryId",
    element: <Blog />,
    errorElement: <Home />,
  },
  {
    path: "/tag/:tagId",
    element: <Blog />,
    errorElement: <Home />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/:year/:month/:day/:post",
    element: <BlogPost />,
    errorElement: <Home />,
  },
  {
    path: "/sitemap",
    element: <div>{generateSiteUrl()}</div>,
    errorElement: <Home />,
  },
]);

export default function AppRouter() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}
