# 我懷孕了

_June 15, 2019 by [Doris](/)_

<figure align="center">
<img alt="ultrasound pic" src="https://d30ay7ug033loj.cloudfront.net/images/ultrasound-pic-first.jpeg" width="100%"/>
</figure>

今年四月在台灣登記結婚了。登記結婚對我來說很不踏實，登記前幾天還跟山地說我真的超緊張。不只是因為擔心登記文件準備得不充分，更多的緊張是…我竟然要登記結婚了！這是有紀錄的耶，不是兩小無猜說說嘴而已。

準備登記的文件非常繁瑣，如果你的另一半是西班牙人，建議你可以閱讀山地的文章[Cómo casarse en Taiwán](https://onepanfamily.com/2020/06/22/como-casarse-en-taiwan/)。但其實找到值得信賴的仲介，把錢花下去，就會比較省事。前幾天正好聽說同事當時在準備登記文件時 （她先生是法國人），她在網路上找的法國仲介非常不靠譜，公證翻譯少蓋章或是文件少準備一份之類的鳥事，整件事情就是勞民傷財什麼事也沒辦成，最後只好在台灣找法文的公證翻譯。

因為疫情關係，不幸中的大幸是山地在台灣可以待很久，所以我們相處相處著，就這樣不小心懷孕了，大概是吃到太多對方的口水就懷孕了吧。（小朋友請不要相信我）。

總之，驗孕驗到第三次，看到清楚的兩條線，才願意相信自己真的懷孕了。當下是「嗯我認了」那種感覺，但山地沒有掉眼淚抱著我轉圈圈這我比較在意。

<div align="center">
<figure style="width:60%;">
<img alt="ultrasound pic" src="https://d30ay7ug033loj.cloudfront.net/images/pregnancy-test-positive-result.jpeg" width="100%"/>
<figcaption>不承認也不行了</figcaption>
</figure>
</div>
<br>

隔了一週去婦產科報到，確實懷孕了。可是醫生說心跳有點慢（正常要超過100bpm，當天只有97bpm）。隔一週再去一次，害呀這下是136bpm，孩子你終於醒啦。只是看婦產科實在是太折磨了，目前去婦產科的經驗都不太好，因為每次都等了至少兩小時以上，明明掛9:00-12:00的早診，都超過下午1:30才看到醫生。其實台灣生育率很高的吧！😑

主圖的超音波照片大概是快9週的時候，看起來很不像人類，四隻腳朝天好像野獸。醫生還說他的背包是便當，這比喻蠻可愛的。言下之意是此刻的媽媽想吃什麼都可以，不用擔心會影響小朋友營養。

還有一件有趣的事。為了懷孕我們會紀錄被撲倒的日子，但第一次看婦產科醫生時，她卻說「已經6週了」，但回頭看紀錄當時的我們沒亂來啊 ヾ( •́д•̀ ;)ﾉ 第一次夫妻關係失和就這樣被醫生陷害。

後來才知道懷孕週數是指這顆卵子的年紀，而不是受精卵的年紀，也就是會相差兩週囉。到底為什麼不算孩子的年紀要算卵子的年紀啦…

為什麼說自己體質不易受孕，因為我有甲狀腺亢進，在十多年前就做了部分切除。但婦產科醫生建議我應該要回診，因為懷孕某個激素(hcg)會讓身體有類似亢進的反應。詳情可以自行谷歌，谷歌後也不要太驚慌，先去掛內分泌科就對了。

看診後，確定要開始吃藥。內分泌科醫生安慰我說，只有第一孕期比較需要吃藥，因為身體還在適應賀爾蒙的改變，有些人到了第二、第三孕期甚至不需要吃藥，連甲亢的情形也可能會一併改善。婦科醫生也說，一定要按時回診、按時吃藥，不吃藥對媽媽、寶寶都更危險。

以上，正式展開我的甲亢孕婦人生，咱們且戰且走。Fighting!