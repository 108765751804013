# 台灣人要在西班牙登記結婚啦

_October 6, 2020 by [Doris](/)_

<figure align="center">
<img alt="西班牙戶口名簿" src="https://d30ay7ug033loj.cloudfront.net/images/IMG_0789-scaled-e1601978920748-1220x600.jpeg" width="100%"/>
</figure>

這篇文章是寫給**已經在台灣登記結婚**後，婚姻還沒因為流程而破局的伴侶們。 (ﾟ∀。) 趁著自己還有記憶的時候，趕快跟你們分享，不然要是再跑一次的話也會很慌張。（揪竟為何要再跑一次…）

##### 需要向西班牙登記結婚的話，已婚的台灣人需要準備哪些文件？

1. 英文戶籍謄本（不可省略記事）共兩份，一份需要公證人公證。
2. 英文婚姻狀況宣示證明書（Affidavit of marital status）一份。宣示自己在何時結婚以及在該日之前為單身，需本人親自簽名，也需要公證。
3. 英文出生證明一份。若經過翻譯，就需要公證。
4. 英文結婚證書一份。

**英文戶籍謄本**可直跟各區戶政事務所申請，第一份100元，之後每份5元。需要五個工作天，但若有急需的話是可以加快辦理的。有效期限3個月喔，所以也不需要太早申請。

**英文婚姻狀況宣示證明書**（Affidavit of marital status）是我們去西班牙商務辦事處(Google map)詢問的時候，櫃檯小姐提供給我們的，這份文件是中英文雙語的。如果你時間允許，建議你可以跑一趟西班牙商務辦事處，把不清楚的流程、需要準備的表格，一次搞定，保證安心。

如何獲得**英文出生證明**？原本計畫是先向戶政事務所申請中文版，再請翻譯。但因為我的出生證明太模糊，翻譯不敢貿然進行。所以信義區戶政事務所的請我打到我出生地的戶政事務所詢問正本，但是正本也非常模糊 (′⌒‵｡)

窮途末路之餘，戶政事務所人員說「你出生的醫院還在營業，不妨去跟他們申請**英文出生證明**吧。」ଘ(੭ˊᵕˋ)੭* ੈ✩‧₊˚她是天使了啊，幫我省了翻譯費3,000元。此外，因為是直接跟醫院申請的英文出生證明，所以就不需要公證喔！

文件備齊後，我找了[林和本公證人](https://www.hblinnotary.com/our-services)協助文件處理，因為他的網站有中英文，讓山地安心。林公證人回信也非常明快，文件用印也謹慎。文件公證的那一天是我準備文件期間最踏實的一天。

**英文結婚證書**在登記結婚那天就現場申請了，一份100元。基本上因為我非常擔心文件申請的不夠，所以我都會多申請幾份，也多公證了一整份文件。為了心安，這些都是小錢啦。

##### 西班牙籍配偶又要準備哪些文件？
西班牙籍的配偶若要在台灣透過西班牙商務辦事處代處理文件的話，山地說需要在辦事處登記自己是居住在台灣的臨時或長期居民（需要證件照），他是在繳交文件的同一天一起辦理登記的。

以下文件都需要準備一份正本：

1. 雙方護照。
2. 近六個月內由西班牙戶政事務所(Registro Civil Español)核發的西班牙出生證明。
3. 近六個月內由西班牙戶政事務所(Registro Civil Español)所出具的婚姻狀態證明(FE DE VIDA Y ESTADO)。
4. 申請表格，並經由西班牙籍配偶簽名。

上述的第2&第3份文件，山地說都可以線上申請，網址👉 [Ministerio Justicia](https://www.mjusticia.gob.es/cs/Satellite/Portal/es/servicios-ciudadano)

<div>
<figure align="center">
<div>
<iframe loading="lazy" title="Certificados Digitales" width="100%" style="aspect-ratio: 16/9;" src="https://www.youtube.com/embed/mwJ7gY5X8qk?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
</iframe>
</div>
<figcaption>線上申請西班牙出生證明的YouTube教學</figcaption>
</figure>
</div>

雙方文件備齊送到辦事處後，他們會送到西班牙駐馬尼拉大使館。辦事員小姐說因為疫情關係大概需要2~3個月才會寄回台灣 (´⊙ω⊙`)，到時候他們會郵件通知西班牙籍配偶，再帶護照過去領文件即可。我們在7月初去辦這些程序的，山地在9月下旬就收到郵件通知可以領取啦！

獲得結婚證書一只，還有一本帥氣的黑色戶口名簿  😎
我…我…天真以為，領到文件就可以辦理依親簽證，沒想到西班牙的結婚證明還需要送到馬德里用印跑關。然後，我就把文件寄給了遠在荷蘭的山地，他再寄給馬德里的代辦處理。好吧，就讓文件代替我們出國旅行吧 (´_ゝ`)

跑關遊戲未完待續。🏃🏼‍♂️ 🏃🏼‍♂️ 🏃🏼‍♂️ 除了簽證或居留證之外，下一個需要跑關的應該是新生兒的登記了 (〃▽〃) 到時候再跟你們分享。