# Impuestos en Taiwán

_Diciembre 23, 2021 by [Santiago](/)_

<figure align="center">
<img alt="moneda de dos euros" src="https://d30ay7ug033loj.cloudfront.net/images/2-euro-coin.jpeg" width="100%"/>
</figure>

Cuando vives y trabajas en diferentes países una de las cosas que debes tener en cuenta es cómo gestionar el pago de impuestos. En este post quiero compartir mi caso particular por si puede ayudar a alguien que se encuentre en la misma situación.

Vivo en Taiwán con mi esposa, que es ciudadana taiwanesa, y dado que ya he residido en este país por más de noventa días me corresponde declarar mis ingresos y pagar impuestos en este país.

Mis ingresos provienen del trabajo que realizo para una empresa de Países Bajos, que me paga en euros en una cuenta bancaria domiciliada en Países Bajos. Puede que en algunos sitios leas que en situaciones como esta podrías evitar declarar ningún ingreso y no pagar impuestos, dado que parece difícil que la administración taiwanesa tenga acceso a esos datos. Esto es una decisión personal y cada uno decide qué acciones tomar y asumir las consecuencias de las mismas. En mi caso he preferido hacer las cosas como creo que son correctas y tener la conciencia tranquila.

## ¿Cuándo hay que pagar impuestos?

La declaración de impuesto de un determinado año se hace en el mes de Mayo del año siguiente. El pago o devolución también se debe hacer o se produce en ese mismo mes.

## ¿Qué documentos necesitas?

Debes presentar dos tipos de documentos, los relacionados con tu identificación y los relacionados con tus ingresos.

En mi caso, ARC y pasaporte y mi declaración de la renta del país donde recibo mis ingresos, Países Bajos. ¿Por qué tengo que hacer la declaración de la renta también en Países Bajos? Dado que mi salario se paga en Países Bajos y tengo ciertas propiedades en este país, me interesa hacer la declaración de la renta para recuperar algunas de las retenciones que se realizan sobre mi salario. En esta declaración de la renta, entre otras cosas, figuran cuáles han sido mis ingresos reales a lo largo del año, que es el dato que se va a usar para calcular cuánto corresponde pagar en impuestos.

## ¿Dónde realizar la declaración?

Con los papeles listos mi esposa y yo nos dirigimos a la oficina que te quede más cercana. Para encontrar la tuya puedes buscar por ["National Taxation Bureau"](https://www.ntbt.gov.tw/English) en Google. Una vez allí solicitamos hacer la declaración conjunta, de manera que añaden los datos de mis ingresos a la información fiscal que obtienen automáticamente de mi esposa. Con todos los datos introducidos el sistema selecciona cuál es la fórmula que es más ventajosa, es decir, la que significa menor pago de impuestos. Si estás de acuerdo te imprimen un documento con el resultado final de la declaración, cuánto has de pagar o cuánto te devolverán, dependiendo de cada situación.

## ¿Cómo se paga?

En el documento final se detallan todas las formas de pago. Hay muchas opciones, desde pago en efectivo, transferencia bancaria, con tarjeta o incluso en 7-eleven.

Pagar impuestos es algo que intimida, especialmente si lo tienes que hacer en un país extranjero. Cada caso es distinto y tendrá vías distintas de resolución.

Espero que el mío te pueda ayudar.

Si quieres más información te recomiendo consultar este [blog](https://www.foreignersintaiwan.com/blog-370963385326684/income-tax-filing-for-expatriates-in-taiwan) con una series de FAQs que te pueden ayudar en tu caso.
