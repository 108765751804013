# Mosquitos en Taiwán

<figure align="center">
<img alt="foto de matrimonio" src="https://d30ay7ug033loj.cloudfront.net/images/taipei-city-view.jpeg" width="100%"/>
</figure>

_July 15, 2020 by [Santiago](/)_

Si eres una de esas personas cuya sangre los mosquitos adoran, la única que recibe las picaduras aunque estés rodeada de más gente, y que no te importaría que se extinguiesen por completo de este planeta, yo te saludo.

## Paraíso para los mosquitos

Taiwán es un país con un clima cálido y húmedo la mayor parte del año, con frondosa vegetación incluso en las ciudades, donde no sólo hay multitud de parques, si no también es frecuente encontrar plantas y arbustos en calles y balcones.

<figure align="center">
<img alt="calle con plantas en taipei" src="https://d30ay7ug033loj.cloudfront.net/images/taipei-alley.jpeg" width="80%"/>
</figure>

Estas condiciones son ideales para la proliferación de mosquitos, hambrientos y deseosos de pastar en nuestras delicadas pieles, succionando el néctar sanguíneo y dejándonos de recuerdo una molesta picadura, que nos acompañará por unos cuantos días.

De puertas para dentro la batalla contra estos indeseados insectos es sencilla. La mayoría de las casas disponen de mosquiteras en las ventanas que, si se mantienen en buenas condiciones, previenen su entrada de forma eficaz. Es importante revisar que no existan holguras o pequeños agujeros en ellas porque los mosquitos son muy persistentes, la vida les va en ello, y seguro que acabarán encontrando por donde colarse.

En campo abierto las cosas se ponen más complicadas. Mantenerse en movimiento es una buena estrategia, pero en ocasiones esto no es posible, y ahí es donde atacan. Unos cuantos segundos esperando en un semáforo, el tiempo que tardas en coger la YouBike o en arrancar la GoShare y sin darte cuenta estás alimentando uno de esos chupasangres. Mi consejo es que mantengas siempre un ligero baile corporal durante estas pausas para minimizar los ataques.

Observando a los locales he aprendido que aquí se suele vestir con ropa floja pero larga. Pantalones y camisetas de manga larga son muy habituales cuando la gente sabe que va a estar en el exterior durante mucho tiempo. La primera vez que hice uno de los maravillosos trails en Taipei me extrañaba que con el calor que hacía la gente vistiera con tanta ropa, incluso con pañuelos para cubrir el cuello. Mis extrañezas tuvieron pronta respuesta al ver cómo los mosquitos se ensañaron con mis piernas y brazos en cada pausa que hacía para sacar una foto o beber algo de agua.

## Hualien y parque nacional de Taroko

Uno cree que lo ha visto todo en el mundo de los mosquitos, hasta que visita la región o condado de Hualien, y no porque sean grandes y aterrorizadores, sino más bien lo contrario, son pequeños e imperceptibles, esa es su ventaja evolutiva, son micro-mosquitos. No los oyes ni sientes pero su picadura es de nivel top. Aquí os dejo una foto de cómo dejaron uno de mis brazos, y todo eso usando un repelente.

<figure align="center">
<img alt="brazo con picaduras de mosquitos" src="https://d30ay7ug033loj.cloudfront.net/images/arm-with-mosquito-bites.jpeg" width="80%"/>
</figure>

Por supuesto no quiero desanimar a nadie a que venga a visitar esta región, es realmente hermosa, y si te gusta la naturaleza, grandes montañas y acantilados, es un viaje muy recomendado, pero ven mejor preparado que yo. Aquí os dejo un vídeo de Hualien y del parque nacional Taroko.

<figure align="center">
<iframe width="80%" style="aspect-ratio: 16/9;" src="https://www.youtube.com/embed/fH-Qc0XOqQE" title="Hualien city and Taroko national park with Mavic Mini" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</figure>

## Soluciones

Si vas en con pantalones cortos y camiseta, el movimiento es tu mejor amigo, vigilando la parte posterior de los brazos y piernas, donde es más difícil verlos. Si puedes viste ropa larga y floja, ya que también pueden atravesar prendas finas y ceñidas.

Existen varios repelentes más y menos naturales. Es difícil decir cuánto de bien o mal funcionan, es evidente que en mi caso me han picado igual, pero desconozco cuál habría sido el alcance de las lesiones si no llevase nada de repelente encima.

Existen también tratamiento post-picadura, que ayudan a rebajar la hinchazón y el escozor. Mi mujer me recomendó desde un principio que usase un producto japonés que ayuda a reducir la inflamación y la picazón, y en mi caso me ha funcionado bastante bien.

<figure align="center">
<img alt="calmante picadura mosquitos" src="https://i.ebayimg.com/images/g/yPgAAOSwymxVJ2ua/s-l1600.jpg" width="40%"/>
</figure>

En cualquier caso, con mosquitos o no, Taiwán es un país maravilloso, tanto para visitar como para vivir. Con unas pocas precauciones puedes evitar las molestias de estos pequeños insectos y disfrutar plenamente de la gastronomía, cultura y naturaleza tan diversas que ofrece este país.
