import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import {
  META_DESCRIPTION,
  META_TITLE,
  sections,
  sidebar,
} from "./../content/Content";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

const theme = createTheme();
export default function Contact() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{`Contact - ${META_TITLE}`}</title>
        <meta name="description" content={META_DESCRIPTION} />
      </Helmet>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="OnePanFamily" sections={sections} />
        <main>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
          </Grid>
        </main>
      </Container>
      <Footer title="" description="" />
    </ThemeProvider>
  );
}
